export function routerHandlerInit(store, router) {
    const token = store.getters.accessToken;
    const currentRoute = router.history.current.path;
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        if ((to.path === '/login' || to.path === '/register') && token) {
            next('/');
        } else if (requiresAuth && !token) {
            next('/login');
        } else {
            next();
        }
    });

    if (token) {
        window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }


    window.axios.interceptors.response.use(null, (err) => {
        const { status, data } = err.response
        if (status === 401) {
            if (data.message === 'Unauthenticated.') {
                store.commit('LOGOUT');
                location.reload();
            }
        }

        if (status === 503) {
          location.href = 'maintenance';
        }
        return Promise.reject(err);
    });
}
