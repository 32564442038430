export const clientsChecked = state => {
  let checked = true;
  state.clients.forEach(client => {
    if(client.checked === false) { checked = false }
  })
  return checked
}

export const amountLoaded = state => state.amountLoaded

export const selectedProductId = (state, getters) => {
  const selectedProduct = getters.products.find(item => item.id === state.selectedProductId);

  return selectedProduct ? Number(state.selectedProductId) : 0;
}

export const selectedProductName = (state, getters) => {
  const selectedProduct = getters.availableProducts.find(item => item.id === getters.selectedProductId);

  return selectedProduct?.name;
}

export const availableProducts = state => {
  const { products } = state

  if (!products) { return }

  let items = products
      .filter(item => item.mergeProduct.id !== 22)
      .map((item) => {
        item.id = item.mergeProduct.id;
        item.name = item.mergeProduct.name;
        item.value = item.mergeProduct.id;
        item.sort = item.mergeProduct.sort;
        return item;
      })
      .sort((a, b) => a.sort - b.sort);

  return items.filter(item => !(item.mergeProduct.id === 16))
}

export const autoplusProduct = state => {
  const { products } = state

  if(!products) { return }

  return products.find(item => (item.mergeProduct.id === 16))
}

export const isLoyalClient = state => {
  const { clients } = state;

  if (!clients) { return false; }

  return clients[0]?.loyalClient;
}

export const minRiskRating = state => {
  const { clients } = state;

  if(!clients) { return 0; }

  //Минимальный класс страхование
  let minRiskRating = 15;

  // Получаем класс страхование каждого клиента
  clients.map(v => {
    const { riskRating } = v
    let clientClassRating;


    if (riskRating) {
      clientClassRating = Number(v["riskRating"].substr(0,2))
    } else {
      clientClassRating = 0;
    }

    if (clientClassRating < minRiskRating) { minRiskRating = clientClassRating; }
  })

  return minRiskRating;
}

export const carYearAndCarType = state => {
  const { cars, isClient } = state

  let carInfo = cars.map(v => ({ prodYear: Number(v["prodYear"]), tfType: String(v["tfType"]) }))

  let minCarYear = new Date().getFullYear();

  if (isClient || (!isClient && carInfo.length <= 1)) {
    let isCarLight = true;

    carInfo.forEach(car => {
      if (car["prodYear"] < minCarYear) { minCarYear = car["prodYear"]; }
      if (car["tfType"] !== '1') { isCarLight = false; }
    });

    return { minCarYear, isCarLight };
  } else {
    let isCarLight = false;

    carInfo.forEach((car) => {
      if (car["prodYear"] < minCarYear) { minCarYear = car["prodYear"]; }
      if (car["tfType"] === "1") { isCarLight = true; }
    });

    return { minCarYear, isCarLight };
  }
}

export const productsRegion = state => {
  let regions = state.cars.map(v => v["territory"]);

  return [...new Set(regions)];
}

export const noBasketRegions = (state, getters) => {

  if(getters.productsRegion.some(data => data == "59" || data == "55" ||data == "56" || data == "57" || data == "58" ||  data == "11" || data == "04" || data == "06" || data == "07" || data == "08"
      || data == "22" ||  data == "40" || data == "39" || data == "42" || data == "56" || data == "28")){
    return true;
  }

  return false;
}

export const isStandard = state => {
  let isStandard = true;

  if (state.period < 12) { isStandard = false }

  if (state.clients.length > 5) { isStandard = false }

  if (state.cars.length > 5) {
    isStandard = false;
  }
  return isStandard;
}