import moment from "moment";
import {uuidv4} from "../../plugins/Helpers";
import _ from "lodash";

export default {
    namespaced: true,
    state: {
      draftId: uuidv4(),
      phoneNumber: null,
      holder: null,
      itemSub: null,
      insureds: [],
      tariff: null,
      manId: null,
      comment: null,
      showVerify: false,
      rating: null,
      paramOtp: null
    },
    getters: {
        phoneNumber: state => state.phoneNumber,
        holder: state => state.holder,
        itemSub: state => state.itemSub,
        insureds: state => state.insureds,
        tariff: state => state.tariff,
        manId: state => state.manId,
        comment: state => state.comment,
        showVerify: state => state.showVerify,
        rating: state => state.rating,
        paramOtp: state => state.paramOtp,
    },
    mutations: {
        "SET_PHONE_NUMBER": (state, payload) => state.phoneNumber = payload,
        "SET_HOLDER": (state, payload) => state.holder = payload,
        "SET_ITEM_SUB": (state, payload) => state.itemSub = payload,
        "SET_INSUREDS": (state, payload) => state.insureds = payload,
        "SET_TARIFF": (state, payload) => state.tariff = payload,
        "SET_MAN_ID": (state, payload) => state.manId = payload,
        "SET_COMMENT": (state, payload) => state.comment = payload,
        "SET_SHOW_VERIFY": (state, payload) => state.showVerify = payload,
        "SET_RATING": (state, payload) => state.rating = payload,
        "SET_PARAM_OTP": (state, payload) => state.paramOtp = payload,
    },
    actions: {
        changePhoneNumber: ({commit, dispatch}, newPhoneNumber) => {
            // dispatch('saveDraft');
            commit('SET_PHONE_NUMBER', newPhoneNumber)
        },
        changeHolder: ({ commit, dispatch }, { item }) => {
            //dispatch('saveDraft');
            commit('SET_HOLDER', item);
        },
        changeItemSub: ({ commit, dispatch }, item) => {
            //dispatch('saveDraft');
            commit('SET_ITEM_SUB', item);
        },
        changeInsureds: ({ commit, dispatch }, item) => {
            //dispatch('saveDraft');
            commit('SET_INSUREDS', item);
        },
        changeTariff: ({commit, dispatch}, newTariff) => {
            //dispatch('saveDraft')
            commit('SET_TARIFF', newTariff)
        },
        changeManId: ({commit, dispatch}, newManId) => {
            //dispatch('saveDraft')
            commit('SET_MAN_ID', newManId)
        },
        changeComment: ({commit, dispatch}, newManId) => {
            //dispatch('saveDraft')
            commit('SET_COMMENT', newManId)
        },
        changeShowVerify: ({commit, dispatch}, item) => {
            //dispatch('saveDraft')
            commit('SET_SHOW_VERIFY', item);
        },
        changeRating: ({commit, dispatch}, item) => {
            //dispatch('saveDraft')
            commit('SET_RATING', item);
        },
        changeParamOtp: ({commit, dispatch}, item) => {
            console.log('changeParamOtp',item);
            commit('SET_PARAM_OTP', item);
        },
        clearUnsubscribe: ({commit, dispatch}) => {
            commit('SET_SHOW_VERIFY', false);
            commit('SET_COMMENT', null);
            commit('SET_RATING', null);
            commit('SET_PARAM_OTP', null);
        },
    }
}
