import moment from "moment";
import {getPeopleByIin} from "../../api/info";
import {uuidv4} from "../../plugins/Helpers";
import _ from 'lodash'
import axios from "axios";
import star from "../../components/Icons/Star";

export default {
  namespaced: true,
  state: {
    draftId: uuidv4(),
    clients: [],
    cars: [],
    startDate: null,
    period: 12,
    email: null,
    reward: 100000,
    holder: null,
    insureds: [],
    vehicle: {
      uuid: uuidv4()
    },
    availableCars: [],
    creationTime: moment(),
    bonusId: null,
    tariff: null,
    manId: null,
  },
  mutations: {
    "SET_START_DATE": (state, payload) => state.startDate = payload,
    "ADD_CLIENT": (state, payload) => state.clients.push(payload),
    "REMOVE_CLIENT": (state, index) => state.clients.splice(index, 1),
    "CHANGE_VEHICLE": (state, payload) => state.vehicle = payload,
    "SET_REWARD": (state, payload) => state.reward = payload,
    "SET_TARIFF": (state, payload) => state.tariff = payload,
    "SET_MAN_ID": (state, payload) => state.manId = payload,
    "CHANGE_HOLDER": (state, payload) => state.holder = payload,
    "ADD_INSURED": (state, payload) => state.insureds.push({...payload, uuid: uuidv4()}),
    "CHANGE_INSURED": (state, { item, index }) => { state.insureds[index] = item },
    "REMOVE_INSURED": (state, index) => state.insureds.splice(index, 1),
    "RESET_INSUREDS": (state) => state.insureds = [],
    "SET_CHECK_HOLDER": (state, payload) => state.holder.checked = payload,
    "SET_HOLDER_CHECK_MESSAGE": (state, payload) => state.holder.checkMessage = payload,
    "CHANGE_BONUS_ID": (state, payload) => state.bonusId = payload,
    "RESET_CASCO_FORM": (state) => {
      state.draftId = uuidv4()
      state.startDate = moment().add(1, "days").format("YYYY-MM-DD")
      state.period = 12
      state.email = null
      state.reward = 100000
      state.holder = { uuid: uuidv4(), individual_id_number: ''}
      state.insureds = []
      state.vehicle = { uuid: uuidv4() }
    }
  },
  getters: {
    email: state => state.email,
    startDate: state => state.startDate,
    endDate: (state, getters) =>  { const { period, startDate } = getters; return moment(startDate).add(period, "M").subtract(1, "days").format('YYYY-MM-DD'); },
    reward: state => state.reward,
    holder: state => state.holder,
    insureds: state => state.insureds,
    vehicle: state => state.vehicle,
    bonusId: state => state.bonusId,
    tariff: state => state.tariff,
    manId: state => state.manId,
    availableCars: state => state.availableCars,
    finalFormValue: (state, getters, root) => {
      return {
        draft_id: state.draftId,
        phone_number: root.auth.authUser.phone_number,
        insurance_type: root.policyForm?.insuranceProduct?.insurance_type || 2221,
        holder: getters.holder,
        start_date: getters.startDate,
        vehicle: getters.vehicle,
        insureds: getters.insureds,
        tariff: getters.tariff,
        reward: getters.reward,
        bonus_id: getters.bonusId,
        source: 'mobile'
      }
    },
    finalSubscriptionFormValue: (state, getters, root) => {
      return {
        draft_id: state.draftId,
        phone_number: root.auth.authUser.phone_number,
        insurance_type: 2225,
        holder: getters.holder,
        start_date: getters.startDate,
        vehicle: getters.vehicle,
        insureds: getters.insureds,
        tariff: getters.tariff,
        man_id: getters.manId,
        bonus_id: getters.bonusId,
        source: 'mobile'
      }
    },
  },
  actions: {
    changeReward: ({commit, dispatch}, newReward) => {
      dispatch('saveDraft');
      commit('SET_REWARD', newReward)
    },
    changeTariff: ({commit, dispatch}, newTariff) => {
      dispatch('saveDraft')
      commit('SET_TARIFF', newTariff)
    },
    changeManId: ({commit, dispatch}, newManId) => {
      dispatch('saveDraft')
      commit('SET_MAN_ID', newManId)
    },
    changeStartDate: ({commit, dispatch}, startDate) => {
      dispatch('saveDraft');
      commit('SET_START_DATE', startDate)
    },
    changeHolder: ({ commit, dispatch }, {item}) => {
      dispatch('saveDraft')
      commit('CHANGE_HOLDER', item);
    },
    changeVehicle: ({ commit, dispatch, state}, {item}) => {
      dispatch('saveDraft')
      commit('CHANGE_VEHICLE', {...state.vehicle, ...item});
    },
    changeInsured: ({ commit, dispatch }, payload) => {
      if(payload.item.individual_id_number) {
        dispatch('saveDraft')
      }
      commit("CHANGE_INSURED", payload);
    },
    addInsured({commit}, payload) {
      commit('ADD_INSURED', payload);
    },
    addNewInsured({commit}) {
      commit('ADD_INSURED', {
        uuid: uuidv4(),
        individual_id_number: null,
        gender: null,
        doc_type: null,
        doc_number: null,
        doc_issue_date: null,
        address_region: null,
        address_state: null,
        address_city: null,
        address_street_full: null,
        address_street_no: null,
        address_flat_no: null,
      })
    },
    removeInsured({commit, dispatch}, index) {
      dispatch('saveDraft')
      commit("REMOVE_INSURED", index)
    },

    async getPersonInfo({ commit, state }, iin) {
      const isDuplicate = state.clients.some((p) => p.bin === iin);

      try {
        const data = await getPeopleByIin(iin);

        if(isDuplicate) {
          throw { statusCode: "DUPLICATE_ITEM" }
        }

        commit("ADD_CLIENT", data);
      } catch (e) {
        if(e.statusCode) {
          throw e;
        }

        let { data } = e.response;


        throw { message: data.message };
      }
    },
    changeSelectedBonus: ({ commit, dispatch }, bonusID) => {
      dispatch('saveDraft')
      commit('CHANGE_BONUS_ID', bonusID);
    },

    resetForm({ commit }) {
      commit("RESET_CASCO_FORM")
    },

    saveDraft: _.debounce(({ getters }) => {
      window.axios.post('/policy-draft', getters.finalFormValue)
    }, 1000),

    async verifyHolder({ commit, state }) {
      commit('SET_HOLDER_CHECK_MESSAGE', null)

      try{
        await window.axios.post(`/verified-client-dl`, state.holder)
        commit('SET_CHECK_HOLDER', true)
      } catch (e) {
        commit('SET_HOLDER_CHECK_MESSAGE', e?.response?.data?.message)
        commit('SET_CHECK_HOLDER', false)

        throw {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
      }
    }
  }
}
