import {getPeopleByIin} from '@/api/info'
export default {
  state: {
    carInfo: null,
    personInfo: null,
    personInfoError: null,
    propertyOwnerInfo: null,
    propertyOwnerError: null,
    ogpoCarInfo: null,
    ogpoPersonInfo: null,
    fixSum: null,
    fixSumByProduct: null,
    cascoLite: null,
    cascoCost: 0,
  },
  mutations: {
    SET_CAR_INFO(state, payload) {
      state.carInfo = payload;
    },
    SET_PERSON_INFO(state, payload) {
      state.personInfo = payload;
    },
    SET_PERSON_INFO_ERROR(state, payload) {
      state.personInfoError = payload;
    },
    SET_PROPERTY_OWNER_INFO(state, payload) {
      state.propertyOwnerInfo = payload;
    },
    SET_PROPERTY_OWNER_ERROR(state, payload) {
      state.propertyOwnerError = payload;
    },
    CLEAR_CAR_INFO(state) {
      state.carInfo = null;
    },
    CLEAR_PERSON_INFO(state) {
      state.personInfo = null;
    },
    CLEAR_PROPERTY_OWNER_INFO(state) {
      state.propertyOwnerInfo = null;
    },
    SET_OGPO_CAR_INFO(state, payload) {
      state.ogpoCarInfo = payload;
    },
    SET_OGPO_PERSON_INFO(state, payload) {
      state.ogpoPersonInfo = payload;
    },
    CLEAR_OGPO_PERSON_INFO(state) {
      state.ogpoPersonInfo = null;
    },
    CLEAR_OGPO_CAR_INFO(state) {
      state.ogpoCarInfo = null;
    },
    SET_CASCO_LITE(state, payload) {
      state.cascoLite = payload;
    },
    SET_CASCO_COST(state, payload) {
      state.cascoCost = payload;
    },
    SET_FIX_SUM(state, payload) {
      state.fixSum = payload;
    },
    SET_FIX_SUM_BY_PRODUCT(state, payload) {
      state.fixSumByProduct = payload;
    },
  },
  actions: {
    async getCarInfo({ commit }, carNumber) {
      const { data } = await window.axios.get(
        `/info/get-car-by-license-plate?license_plate=${carNumber}`
      );
      commit("SET_CAR_INFO", data);
    },
    async getPersonInfo({ commit }, iin) {
      try {
        const data = await getPeopleByIin(iin);
        commit("SET_PERSON_INFO", data);
      } catch (e) {
        let { data } = e.response;
        commit("SET_PERSON_INFO_ERROR", data);
      }
    },
    async getPropertyOwnerInfo({ commit }, iin) {
      try {
        const data = await getPeopleByIin(iin);
        commit("SET_PROPERTY_OWNER_INFO", data);
      } catch (e) {
        let { data } = e.response;
        commit("SET_PROPERTY_OWNER_ERROR", data);
      }
    },
    async getOgpoCarInfo({ commit }, carNumber) {
      const { data } = await window.axios.post(
        `https://797.polisonline.kz/api/GetByGovNumber`,
        {
          GovNumber: carNumber,
        }
      );

      commit("SET_OGPO_CAR_INFO", data);
    },
    async getOgpoPersonInfo({ commit }, iin) {
      const { data } = await window.axios.post(
        `https://797.polisonline.kz/api/GetByIin`,
        {
          Iin: iin,
        }
      );
      commit("SET_OGPO_PERSON_INFO", data);
    },

    async getFixSum({ commit, rootGetters, dispatch }) {
      const response = await window.axios.post(
        "https://797.polisonline.kz/api/GetFixSum",
        {
          FixSumParameter: [
            {
              Iin: rootGetters.policyForm.persons[0].iin,
              discountBool: false,
              experienceBool: true,
            },
          ],
          GovNumbers: rootGetters.policyForm.cars.map((car) => car.govNumber),
          BeginDate: rootGetters.policyForm.beginDate,
          EndDate: rootGetters.policyForm.endDate,
        }
      );
      await commit("SET_FIX_SUM", response);
      dispatch("getFixSumByProduct");
    },
    async getFixSumByProduct({ commit, getters, rootGetters }) {
      const response = await window.axios.post(
        "https://797.polisonline.kz/api/GetFixSumByProduct",
        {
          discountBool: false,
          ProductID: rootGetters.policyForm.product,
          Sum: String(getters.fixSum.data),
          Kasko: String(getters.cascoCost),
          friendBasket: rootGetters.policyForm.friendBasket,
          Month: rootGetters.policyForm.month,
        }
      );
      commit("SET_FIX_SUM_BY_PRODUCT", response);
    },
  },
  getters: {
    carInfo(state) {
      return state.carInfo;
    },
    personInfo(state) {
      return state.personInfo;
    },
    personInfoError(state) {
      return state.personInfoError;
    },
    propertyOwnerInfo(state) {
      return state.propertyOwnerInfo
    },
    propertyOwnerError(state) {
      return state.propertyOwnerError
    },
    ogpoPersonInfo(state) {
      return state.ogpoPersonInfo;
    },
    ogpoCarInfo(state) {
      return state.ogpoCarInfo;
    },
    cascoLite(state) {
      return state.cascoLite;
    },
    cascoCost(state) {
      return state.cascoCost;
    },
    fixSum(state) {
      return state.fixSum;
    },
    fixSumByProduct(state) {
      return state.fixSumByProduct;
    },
  },
};
