import moment from "moment";
import numeral from "numeral";

export default {
    install(Vue) {

        Vue.filter('dateFormat', (value) => {
            if (!value) return ''
            return moment(String(value)).format("DD.MM.YYYY")
        })

        Vue.filter('phoneFormat', (value) => {
            if (!value) return ''
            return '+7 ' + value.replace(/[^0-9]/g, '')
                .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4')
        })

        Vue.filter('phoneMasked', (value) => {
            if(!value) return ''

            return '+7 ' + value.replace(/[^0-9]/g, '')
                .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) ***-**-$4')
        })

        Vue.filter('priceFormat', value => numeral(value).format('0,0.[00]').replace(/,/g, ' '));

        Vue.prototype.$modalConfig = {
            width: '100%',
            height: '98.5%',
            adaptive: true,
            shiftY: 1,
        }
    }
}


export function qazTranslit(word) {
    let a = {
        Ё: "YO",
        Й: "I",
        Ц: "TS",
        У: "U",
        К: "K",
        Е: "YE",
        Н: "N",
        Г: "G",
        Ш: "SH",
        Щ: "SCH",
        З: "Z",
        Х: "H",
        Ъ: "",
        ё: "yo",
        й: "i",
        ц: "ts",
        у: "u",
        к: "k",
        е: "e",
        н: "n",
        г: "g",
        ш: "sh",
        щ: "sch",
        з: "z",
        х: "h",
        ъ: "",
        Ф: "F",
        Ы: "Y",
        В: "V",
        А: "a",
        П: "P",
        Р: "R",
        О: "O",
        Л: "L",
        Д: "D",
        Ж: "ZH",
        Э: "E",
        ф: "f",
        ы: "y",
        в: "v",
        а: "a",
        п: "p",
        р: "r",
        о: "o",
        л: "l",
        д: "d",
        ж: "zh",
        э: "e",
        Я: "Ya",
        Ч: "CH",
        С: "S",
        М: "M",
        И: "I",
        Т: "T",
        Ь: "",
        Б: "B",
        Ю: "YU",
        я: "ya",
        ч: "ch",
        с: "s",
        м: "m",
        и: "i",
        т: "t",
        ь: "",
        б: "b",
        ю: "yu",
        Ә: "A",
        ә: "a",
        Ғ: "G",
        ғ: "g",
        Қ: "K",
        қ: "k",
        Ң: "N",
        ң: "n",
        Ұ: "U",
        ұ: "u",
        Ү: "U",
        ү: "u",
        Һ: "H",
        һ: "h",
        І: "I",
        і: "i"
    };
    return word
        .split("")
        .map(function(char) {
            if (a[char] !== undefined) {
                return a[char];
            } else {
                return char;
            }
        })
        .join("");
}


export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


const carInfoValidator = (value, args) => {
  console.log(value, args);
  // Return a Boolean or a Promise that resolves to a boolean.
};
