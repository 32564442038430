
export default {
  namespaced: true,
  state: {
    countries: [],
    states: [],
    countryStates: {},
    regions: [],
    stateRegions: {},
    cities: [],
    regionCities: {},
    traveler: [],
    cityList: []
  },
  mutations: {
    "SET_COUNTRIES": (state, payload) => state.countries = payload,
    "SET_STATES": (state, payload) => state.states = payload,
    "SET_COUNTRY_STATES": (state, payload) => state.countryStates = payload,
    "SET_REGIONS": (state, payload) => state.regions = payload,
    "SET_STATE_REGIONS": (state, payload) => state.stateRegions = payload,
    "SET_CITIES": (state, payload) => state.cities = payload,
    "SET_REGION_CITIES": (state, payload) => state.regionCities = payload,
    "SET_TRAVELER": (state, payload) => state.traveler = payload,
    "SET_CITY_LIST": (state, payload) => state.cityList = payload
  },
  getters: {
    countries: state => state.countries,
    states: state => state.states,
    countryStates: state => state.countryStates,
    regions: state => state.regions,
    stateRegions: state => state.stateRegions,
    cities: state => state.cities,
    regionCities: state => state.regionCities,
    traveler: state => state.traveler,
    cityList: state => state.cityList
  },
  actions: {
    getUnionAddressDictionary: async ({ commit }) => {
      const { data } = await window.axios.get('/info/union-dictionary');

      commit("SET_COUNTRIES", data.countries)
      commit("SET_STATES", data.states)
      commit("SET_COUNTRY_STATES", data.countryStates)
      commit("SET_REGIONS", data.regions)
      commit("SET_STATE_REGIONS", data.stateRegions)
      commit("SET_CITIES", data.cities)
      commit("SET_REGION_CITIES", data.regionCities)
    },
    getCountryList: async({commit}) => {
      const { data } = await window.axios.get('/info/country-list');
      commit("SET_COUNTRIES", data)
    },
    getCityList: async ({commit}) => {
      const { data } = await window.axios.get(`/info/city-list`);
      commit("SET_CITY_LIST", data);
    },
    getTravelerDictionary: async({ commit }, product_id) => {
      const { data } = await window.axios.get(`info/travel-dictionaries?product_id=${product_id}`)

      commit("SET_TRAVELER", data);
    }
  }
}
