export default {
    namespaced:true,
    state: {
        form: null,
        agreements: [],
        subscriptions: [],
        lifeAgreements: [],
        loadingAgreements: false,
        loadingSubs: false,
        loadingLife: false,
        policies: []
    },
    mutations: {
        setloadingAgreements(state, isLoading) {
            state.loadingAgreements = isLoading;
        },
        setloadingSubs(state, isLoading) {
            state.loadingSubs = isLoading;
        },
        setloadingLife(state, isLoading) {
            state.loadingLife = isLoading;
        },
        setAgreements (state, agreements) {
            state.agreements = agreements
        },
        setSubscriptions (state, subscriptions) {
            state.subscriptions = subscriptions
        },
        setLifeAgreements (state, lifeAgreements) {
            state.lifeAgreements = lifeAgreements
        },
        setPolicies (state, policies) {
            state.policies = policies
        },
    },
    actions: {
        async getActiveLifeAgreements({commit}) {
            commit('setloadingLife', true);
            try {
                const {data} = await window.axios.get('life/policies/active');
                commit('setLifeAgreements', data.policies || [])
            } catch (e) {
                commit('setLifeAgreements', [])
            } finally {
                commit('setloadingLife', false);
            }
        },

        async getActiveAgreementsSubscription({commit}) {
            commit('setloadingSubs', true);
            try {
                const {data} = await window.axios.get('subscription/active');
                commit('setSubscriptions', data)
                commit('setloadingSubs', false);
            } catch (e) {
                throw e
            } finally {
                commit('setloadingSubs', false);
            }
        },

        async getActiveAgreements({commit}) {
            commit('setloadingAgreements', true);
            try {
                const {data} = await window.axios.get('policies/active');
                this.agreements = data;
                commit('setAgreements', data)
                commit('setloadingAgreements', false);
            } catch (e) {
                throw e
            } finally {
                commit('setloadingAgreements', false);
            }
        },

        async getPolicies({commit}) {
            console.log('=========ENTER========')
            const {data} = await window.axios.get('insurance-types/all')
            commit('setPolicies', data)
        }
    },
    getters: {

    }
}
