<template>
  <div class="modal-layout">
    <div class="modal-header" v-touch:swipe.bottom="closeModal">
      <div class="line"></div>
      <div class="heading">
        <button class="save-btn" @click="saveForm" v-if="showButton">{{ $t('save') }} </button>
        <h1 class="title">{{ title }}</h1>
        <icon icon-name="close-modal-icon" class="icon" @click.native="closeModal"/>
      </div>
    </div>
    <div class="modal-body" id="modal-body" :class="{'gray': isGray, 'full-height': fullHeight}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalLayout",
  props: {
    title: {
      type: String
    },
    showButton:{
      type: Boolean
    },
    isGray:{
      type: Boolean
    },
    fullHeight: {
      type: Boolean
    }
  },
  mounted() { // для того чтобы скролл не ломался в модалке
    (function () {
      let _overlay = document.getElementById('modal-body');
      let _clientY = null; // remember Y position on touch start

      _overlay.addEventListener('touchstart', function (event) {
        if (event.targetTouches.length === 1) {
          // detect single touch
          _clientY = event.targetTouches[0].clientY;
        }
      }, false);

      _overlay.addEventListener('touchmove', function (event) {
        if (event.targetTouches.length === 1) {
          // detect single touch
          disableRubberBand(event);
        }
      }, false);

      function disableRubberBand(event) {
        let clientY = event.targetTouches[0].clientY - _clientY;

        if (_overlay.scrollTop === 0 && clientY > 0) {
          // element is at the top of its scroll
          event.preventDefault();
        }

        if (isOverlayTotallyScrolled() && clientY < 0) {
          //element is at the top of its scroll
          event.preventDefault();
        }
      }

      function isOverlayTotallyScrolled() {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
        return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
      }
    }())
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    saveForm(){
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>
  .save-btn{
    background-color: transparent;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2AA65C;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
  }
  .modal-body.gray{
    background: #F7F7F7;
    height: 100vh;
  }
  .modal-body.full-height{
    height: calc(100vh - 100px);
  }
</style>