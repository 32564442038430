import Cookies from "js-cookie";
import eventLogger from "../../services/eventLogger";

export default {
    state: {
        authUser: JSON.parse(sessionStorage.getItem('auth_user')) || null,
        accessToken: sessionStorage.getItem('access_token') || null,
        homebankToken:  sessionStorage.getItem('homebank_token') || null
    },
    mutations: {
        SET_ACCESS_TOKEN(state, { access_token, homebank_token, enc }) {
          sessionStorage.setItem('access_token', access_token);
          sessionStorage.setItem('homebank_token', homebank_token);
          state.accessToken = access_token;
          state.homebankToken = homebank_token;
          window.axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
        },
        SET_AUTH_USER(state, user) {
          sessionStorage.setItem('auth_user', JSON.stringify(user));
          state.authUser = JSON.parse(JSON.stringify(user));
        },
        LOGOUT(state) {
          sessionStorage.removeItem('access_token');
          sessionStorage.removeItem('auth_user');
          state.accessToken = null;
          state.authUser = null;
        }
    },
    actions: {
        async signInByCaskey({commit, dispatch}, caskey) {
          const ipResponse = await dispatch('getIp')

          const payload = { caskey, ip_address: ipResponse.ip, region: ipResponse.region, city: ipResponse.city }

          eventLogger('login_start');
          
          const {data} = await window.axios.post('/auth/homebank-oauth', payload);

          commit('SET_ACCESS_TOKEN', data);
        },
        async signInByToken({commit, dispatch}, token) {
          const {data} = await window.axios.post('/auth/login-by-hb-token', {token});
          console.log('server data > ',data);
          commit('SET_ACCESS_TOKEN', data);
        },
        async signIn({commit, dispatch}, payload) {
            try {
              const ipResponse = await dispatch('getIp')

              payload.ip_address = ipResponse.ip;
              payload.region = ipResponse.region;
              payload.city = ipResponse.city;

              const { data } = await window.axios.post('/auth/login', payload);

              commit('SET_ACCESS_TOKEN', data)
            } catch (e) {
                throw e;
            }
        },
        async getIp() {
          const ipResult = { ip: null,  region: null, city: null };

          try{
            const {data} = await window.axios.get('https://pro.ip-api.com/json/?fields=57368&key=n75T4kv8DIpKhli');

            ipResult.ip = data.query;
            ipResult.region = data.regionName;
            ipResult.city = data.city;
          } catch (e) {
            //alert(e.message || e)
            console.log(e)
          }
          return ipResult
        },

        async signUp({commit}, {individual_id_number, phone_number, password, email, terms}) {
            try {
                const { data } = await window.axios.post('/auth/register', {
                    individual_id_number,
                    phone_number,
                    password,
                    email,
                    terms
                });
                commit('SET_ACCESS_TOKEN', data)
            } catch (e) {
                throw e;
            }
        },
        async getAuthUser({commit}, iin) {
            try {
                const {data} = await window.axios.get('/user');
                commit('SET_AUTH_USER', data);
            } catch (e) {
                throw e;
            }
        },
        async logOut({commit, state}) {
            const config = {
              headers: {Authorization: `Bearer: ${state.accessToken}`}
            };
            try {
                const {data} = await window.axios.post('/auth/logout', config);
                commit('LOGOUT', data)
            } catch (e) {
                throw e;
            }
        }
    },
    getters: {
        status: state => !!(state.authUser || state.accessToken),
        authUser: state => state.authUser,
        accessToken: state => state.accessToken,
        homebankToken: state => state.homebankToken,
        enc: state => state.enc
    },
};
