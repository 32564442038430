export default {
  main: 'Главная',
  goToMain: 'На главную',
  success: 'Успешно',
  error: 'Error',
  //General
  goBack: 'Назад',
  continueNext: 'Далее',
  confirmation: 'Подтверждение',
  confirm: 'Подтвердить',
  details: 'Узнать подробнее',
  cost: 'Стоимость',
  save: 'Сохранить',
  close: 'Закрыть',
  send: 'Отправить',
  extend: 'Продлить',
  changePolicy: 'Изменить полис',
  cascoPriceLimit: 'Лимит страховой выплаты %{reward} тг.',
  cascoOnlyCars: 'Только легковые автомобили',
  phoneNumber: 'Номер телефона',
  enterPhoneNumber: 'Введите номер телефона',
  incorrectPhoneNumber: 'Неверный номер телефона',
  fullName: 'ФИО',
  insurance: 'Страховка',
  changeTariff: 'Сменить тариф',

  insurancePolicy: 'Страховой полис',
  halykIC: 'СК Халык',
  selectedPayment: 'Избранный платеж',

  salary: 'Зарплатная',
  agreementDate: 'Дата начала договора',
  noAgreements: 'У вас пока нет действующих договоров',
  policyStartDate: 'Дата начала полиса',
  bonusProduct: 'Бонусный продукт',
  bonusProductWillAdded: '*Бонусы будут начислены на 15 день',
  upTo25percent: 'До 25% Бонусов',
  agreeWithTerms: "Вводя код, Вы подписываете Заявление(-я) на страхование и {0} страхователя, подтверждаете ознакомление с {1} страхования и получение копии Правил страхования, а также соглашаетесь c тарифами и условиями АО «Страховая компания «Халык»",
  agreedToInsuranceTerms: 'Я согласен(а) с {0} страхования и с тарифами АО “Страховая компания “Халык”.',
  readInsuranceTerms: 'С {0} страхования ознакомлен(а)',
  declaration: 'Декларацию',
  declarationTitle: 'Декларация',
  rules: 'Правилами',
  apartmentInsurance: 'Страхование имущества',
  buyPolicy: 'Купить полис',
  allVerifiedDisabled: 'В связи с недоступностью государственных баз данных, Ваша заявка будет обработана сразу после включения верификации или нашим менеджером. Ваш страховой полис будет создан до 00:00',
  loyalClientModalTitle: 'Уважаемый клиент',
  loyalClientModalText: 'Вам предоставлена дополнительная страховка  к полису ОГПО ВТС! Мы ценим наших постоянных клиентов.',
  dearClient: 'Уважаемый клиент',
  visitBranchToSign: 'Для переоформления договора необходимо обратиться в офис страховой компании',

  cantChangeNumber: 'Для изменения номера телефона, нужно изменить номер в Homebank',

  //Main menu
  menu: 'Меню',
  products: 'Продукты',
  insuredCase: 'Страховой случай',
  aboutUs: 'О нас',
  specialOffers: 'Акции',
  language: 'Язык',
  pay: 'Оплатить',

  //Agreements tab
  agreements: 'Договоры',
  effectiveAgreements: 'Действующие',
  expiredAgreements: 'Истекшие',
  myGifts: 'Мои подарки',
  myMedCards: 'Мед карты',

  //medical cards
  medInsureds: 'Застрахованные',
  medCenterAddresses: 'Адреса медучреждений',
  medPrograms: 'Медпрограмма',
  //Chat tab
  chat: 'Чат',
  writeToWhatsapp: 'Написать на WhatsApp',
  callUs: 'Позвонить нам',
  chatUs: 'Написать нам (чат-бот)',
  getAPolicy: 'Оформить полис',
  leaveFeedback: 'Оставить отзыв',
  support: 'Поддержка',
  
  //Payment tab
  paymentFailed: 'Платеж не прошел',
  paymentSuccess: 'Платеж принят',
  paymentReceipt: 'Квитанция об оплате и статус платежа доступны в истории',

  //About us tab
  halykTextBold: 'Страховая компания "Халык"',
  halykText: ' - универсальная страховая компания, оказывающая полный спектр страховых услуг по всем категориям юридических и физических лиц.Входит в крупнейшу финансовую группу в Казахстане - "Halyk Group". На рынке страхования уже 25 лет.',
  address: 'Адрес',
  phones: 'Телефоны',
  schedule: 'График работы',
  goToHistory: 'Перейти в историю',
  showOnMap: 'Показать на карте',
  branches: ['отделение', 'отделения', 'отделений'],
  listOfBranches: 'Список филиалов',

  //Credit card
  cardNumber: 'Номер карты',
  cardDate: 'ММ/ГГ',
  payButton: 'Оплатить',

  //Languages tab
  langRus: 'Русский',
  langKaz: 'Казахский',
  langEng: 'Английский',

  logout: 'Выход',

  //Notifications tab
  notifications: 'Уведомления',
  policyEnd: 'Окончание полиса',
  specialOffer: 'Акция',
  kaskoLightOffer: 'На оформление KACKO Light',
  markAllAsRead: 'Отметить все прочитанным',

  //Feedback tab
  feedback: 'Отзыв',
  pleaseRateService: 'Пожалуйста, оцените качество нашего сервиса:',
  pleaseRateBonuses: 'Пожалуйста, оцените представленную линейку бонусов:',
  pleaseRateManager: 'Пожалуйста, оцените работу менеджера(при его наличии):',
  comments: 'Комментарии:',
  comment: 'Комментарий',
  write: 'Написать...',

  //SosForm tab
  insuranceProduct: 'Страховой продукт',
  attachDocs: 'Приложите документы',
  uploadIDCard: 'Удостоверение личности с двух сторон',
  IDCardFront: 'Лицевая часть',
  IDCardBack: 'Оборотная часть',
  otherDocs: 'Другие документы',
  addDocument: 'Добавить документ',
  iin: 'ИИН',
  sex: 'Пол',
  maxDocSize: 'Не удалось загрузить данный файл макс. размер 10МБ',

  policyNumber: 'Номер договора',

  //SosNavigation
  sosTitle: 'По какому продукту наступил страховой случай?',
  sendRequest: 'Оставить заявку',

  //Chat section
  askUs: 'Спрашивайте',

  //AddCarInfo
  addCarNumber: 'Введите номер машины',

  //AddCascoLite
  addCascoBoth: 'Добавить КАСКО лайт (обоюдка)',
  premium: 'Премия',
  insuranceLimit: 'Лимит страховой выплаты',
  payAnotherCard: 'Оплатить банковской картой',

  //AddCountry
  countryDestination: 'Страна поездки',
  addCountry: 'Добавить страну',

  //AddPersonButton
  addInsured: 'Добавить застрахованного',
  removeInsured: 'Убрать застрахованного',
  mainInsureds:'Основной страхователь',
  //AddPersonInfo
  enterIIN: 'Введите ИИН',
  enterLicenceCardNumber: 'Введите номер ВУ',
  //CheckboxAndSelect
  addCascoLight: 'Добавить КАСКО лайт',
  cascoLight: 'КАСКО лайт',

  //CheckboxRadioInputList
  selectBonusProduct: 'Выберите бонусный продукт',
  additionalProduct: 'Дополнительный бонус',

  //CreditCardSelect
  addCard: 'Добавить карту',
  newCard: 'Новая карта',

  //FinalAmount
  total: 'Итого',

  productRules: 'Правила страхования',

  //SearchSelect
  nothingFound: 'Ничего не найдено',
  loading: 'Загрузка...',

  //SelectDate
  today: 'Сегодня',
  yesterday: 'Вчера',

  selectDate: 'Выберите дату',
  jan: 'Январь',
  feb: 'Февраль',
  mar: 'Март',
  apr: 'Апрель',
  may: 'Май',
  jun: 'Июнь',
  jul: 'Июль',
  aug: 'Август',
  sep: 'Сентябрь',
  oct: 'Октябрь',
  nov: 'Ноябрь',
  dec: 'Декабрь',

  janShort: 'Янв',
  febShort: 'Фев',
  marShort: 'Мар',
  aprShort: 'Апр',
  mayShort: 'Май',
  junShort: 'Июн',
  julShort: 'Июл',
  augShort: 'Авг',
  sepShort: 'Сен',
  octShort: 'Окт',
  novShort: 'Ноя',
  decShort: 'Дек',

  sun: 'Воскресенье',
  mon: 'Понедельник',
  tue: 'Вторник',
  wed: 'Среда',
  thu: 'Четверг',
  fri: 'Пятница',
  sat: 'Суббота',

  sunShort: 'Вос',
  monShort: 'Пон',
  tueShort: 'Вто',
  wedShort: 'Сре',
  thuShort: 'Чет',
  friShort: 'Пят',
  satShort: 'Суб',

  sunMin: 'Вс',
  monMin: 'Пн',
  tueMin: 'Вт',
  wedMin: 'Ср',
  thuMin: 'Чт',
  friMin: 'Пт',
  satMin: 'Сб',

  //GetInfoCar
  carModel: 'Модель авто',
  carRegNumber: 'Регистрационный номер авто',

  //GetInfoPerson
  driverName: 'Имя водителя',

  //Modals
  terms: 'Условия',
  understand: 'Понятно',

  selectCity: 'Выберите город',
  findMyCity: 'Найти ваш город',

  //DocumentsListModal
  docList: 'Перечень документов',
  notAllDocs: 'Можно приложить не все документы, при этом, заявка будет рассматриваться дольше:',
  photoOfID: 'Фото удостоверения личности',
  photoTechPassport: 'Фото техпаспорта пострадавшего транспортного средства; Материалы ГАИ (протокол и схема происшествия ДТП)',
  photoCourt: 'Фото постановления суда (после решения суда можно доложить)',
  photoBankDetails: 'Сведения о банковских реквизитах получателя выплаты (фото, PDF или текстовый файл)',

  ifAggrieved: 'При наличии пострадавших, которым причинен вред жизни или здоровью дополнительно необходимо предоставить:',
  photoSick: 'Фото больничного листа/ выписной эпикриз и т.п.',
  photoFiscal: 'Фото фискальных, товарных чеков',
  photoDisability: 'Фото справки об установлении инвалидности',
  photoDeath: 'Фото свидетельства о смерти (или нотариально заверенной копии)',

  //FeedbackResultModal
  lowFeedback: 'Извините за низкий уровень сервиса, мы примем меры!',
  mediumFeedback: 'Спасибо за отзыв, будем становиться лучше!',
  highFeedback: 'Спасибо за высокую оценку нашей работы!',

  //SosResultModal
  carInsurance: 'Страхование авто',
  sosRequestSuccess: 'Заявка принята. Уважаемый клиент! Мы свяжемся с Вами в ближайшее время',

  //OtpVerify
  smsConfirm: 'Введите код из SMS, отправленный на номер',
  nextCode: 'Получить новый код можно через',
  nextCodeSec: 'сек',
  sendCodeAgain: 'Отправить код снова',

  //CascoLiteForm
  firstName: 'Имя',
  lastName: 'Фамилия',
  surName: 'Отчество',
  dateOfBirth: 'Дата рождения',
  location: 'Место нахождения',
  city: 'Город',
  street: 'Микрорайон/улица',
  building: 'Дом',
  apartment: 'Квартира',
  docType: 'Тип документа',
  docIdConfirm: 'Документ, подтверждающий личность',
  passportConfirm: 'Паспортные данные, серия и номер',
  docNumber: 'Номер документа',
  docGiveDate: 'Дата выдачи документа ',
  kazID: 'Удостоверение РК',
  passport: 'Паспорт',
  nonResidentPermit: 'Вид на жительство нерезидента',
  driverLicense: 'Водительское удостоверение',
  male: 'Мужской',
  female: 'Женский',
  birthCertificate: 'Свидетельство о рождении',

  //CovidProtect
  typeOfTrip: 'Тип поездки',
  typeOfTripAddition: '*Застрахованный вправе пользоваться данным полисом в случае многократных деловых поездок в течение выбранного периода.',
  selectPackage: 'Выберите пакет',
  destinationCountry: 'Страна поездки',
  selectInsuranceSum: 'Выберите сумму страхования',
  departureDate: 'Дата отъезда',
  arrivalDate: 'Дата прибытия',
  insurancePeriod: 'Период страхования',
  tripDuration: 'Длительность поездки',

  insuranceCountryWarning: 'На момент оформления страховки ни один из застрахованных не должен находиться в стране временного прибывания.',
  mainPolicyHolder: 'Главный страхователь',
  touristBirthDate: 'Дата рождения туриста',
  lastNameInLatin: 'Фамилия на латинице',
  firstNameInLatin: 'Имя на латинице',
  selectDocument: 'Выберите документ',
  removeTourist: 'Убрать туриста',
  addTourist: 'Добавить туриста',
  infoAbout: 'Данные ',
  ofTourist: ' туриста',

  //CovidProtectSports
  sportType: 'Вид спорта',
  isStudentAthlete: 'Является ли обучаемый спортсменом?',
  sports1: 'Бейсбол, софтбол, баскетбол, поло, акробатика, тяжелая и легкая атлетика',
  sports2: 'Биатлон, ориентирование, пожарно-прикладной, стрельба, фехтование',
  sports3: 'Виндсерфинг, катание на квадроциклах, водных мотоциклах',
  sports4: 'Горные лыжи, катание на сноубордах, фристаил, самбо, дзюдо, велоспорт, борьба, мотобол',
  sports5: 'Дайвинг, подводная охота',
  sports6: 'Кик-боксинг, бокс, каратэ, рукопашный бой, тхэквандо, ушу, сумо, айкидо,кендо, кобудо, кунг-фу, кудо',
  sports7: 'Парашютный, парапланеризм, дельтапланеризм, рафтинг, сноуборд',
  sports8: 'Плавание, синхронное плавание, аэробика, гребля, настольный теннис, батут, гиревой, городошный, гольф',
  sports9: 'Пятиборье, гимнастика, триатлон, парусный, конный, прыжки  в воду',
  sports10: 'Фигурное катание, бобслей, сани, теннис, сквош, бадминтон, конькобежный, лыжный, воднолыжный',
  sports11: 'Хоккей, буер, футбол, регби, альпинизм, скалолазанье, роллер, гандбол, силовое троеборье, сафари',

  alreadyInCountryOfTempResidence: 'Находитесь ли Вы уже в стране временного пребывания?',
  policyHolderTheInsured: 'Страхователь является застрахованным',

  singleTravel: 'Однократная',
  multiTravel: 'Многократная',
  resident: 'Резидент',
  nonResident: 'Нерезидент',
  franchise: 'Франшиза',
  travelIncludeCovid: 'Добавить покрытие COVID-19?',
  //durationsAndDates
  numDays120: '120 дней',
  numDays180: '180 дней',
  numDays30: '30 дней',
  numDays365: '365 дней',
  numDays60: '60 дней',
  numDays90: '90 дней',

  year1: '1 год',
  month1: '1 месяц',
  month3: '3 месяца',
  month6: '6 месяцев',
  month12: '12 месяцев',

  upTo180: 'До 180 дней',
  upTo45: 'До 45 дней',
  upTo90: 'До 90 дней',
  upTo30: 'До 30 дней',
  upTo10: 'До 10 дней',

  order: ['второго', 'третьего', 'четвертого', 'пятого'],

  tourist1info: "Данные первого туриста",
  tourist2info: "Данные второго туриста",
  tourist3info: "Данные третьего туриста",
  tourist4info: "Данные четвертого туриста",
  tourist5info: "Данные пятого туриста",

  //flatOnlineForm
  infoAboutInsuredProperty: 'Данные о застрахованном имуществе',
  country: 'Страна',
  region: 'Регион',
  district: 'Район',
  insuredAndPolicyHolderMatch: 'Данные страхователя и застрахованного совпадают',

  //OgpoForm
  drivers: 'Водители',
  addDriver: 'Добавить водителя',
  cars: 'Автомобили',
  addCar: 'Добавить авто',
  emailNotNecessary: 'Поле для заполнения \'Email\' необязательно',
  yourAdditionalBonus: 'Ваш дополнительный бонус',
  driverAlreadyAdded: 'Водитель с таким ИИН уже добавлен',
  carAlreadyAdded: 'Авто с таким номером уже добавлено',
  serverError: 'Ошибка сервера',
  discount10: 'Скидка 10%',
  autoplusCost: 'Стоимость "Автоплюс online"',
  productDiscount: 'Скидка на продукт',
  bonusSum1: 'Бонус 25%',
  bonusSum2: 'Бонус 22%',
  bonusSum3: 'Бонус 20%',
  bonusSum4: 'Бонус 15%',
  bonusSum11: 'Бонус 30%',

  remainingSum: 'Остаток',

  //TravelerForm
  insuredIsEntitled: 'Застрахованный вправе пользоваться данным полисом в случае многократных деловых поездок в течение выбранного периода. Покрываемые услуги –экстренная медицинская помощь, транспортировка в медицинское учреждение, перевозка тела в случае смерти, оказание экстренной стоматологической помощи, возвращение детей, оплата проезда родственнику застрахованного.',
  insuredSum: 'Страховая сумма',

  //SendGiftToFriend
  giftFriendInsurance: 'Подарите другу дополнительную страховку!',
  giftFriendNumber: 'Введите сотовый номер друга, ему придет смс с дополнительным бонусом “Автоплюс online”',
  giftFriendSent: 'Уважаемый клиент! Дополнительная страховка другу была отправлена.',
  giftFriendSmsSent: 'На сотовый номер друга, направлена СМС со ссылкой на приложение.',
  giftAlreadySent: 'Вы уже отправили подарок другу',
  alreadyRatedInvoice: 'По этому полису уже сохранен отзыв',
  rateSaved: 'Отзыв сохранен',
  //SecondaryPageLayout
  backToMain: '',
  //PaymentNumberContract
  payContractTitle: 'Оплатите по номеру договора',
  payContractPleaceH: 'Номер договора',
  payContractBtn: 'Перейти к оплате',
  payContractTitle_1: 'Продукт:',
  payContractTitle_2: 'Страхователь:',
  payContractTitle_3: 'Застрахованные:',
  payContractTitle_4: 'Объект страхования:',
  payContractTitle_5: 'Договор ',
  payContractTitle_6: 'заключения договора ',

  payCard: 'Оплатить картой',
  agreedChangeTariff: 'Я согласен(а) с {0} страхования и с тарифами АО “Страховая компания “Халык”. С {1} страхования ознакомлен(а)',
  monthlyPayment: 'Ежемесячный платеж',
  forThreeMonthPayment: 'Для Вас платеж на 3 мес',
  paymentDate: 'Дата платежа',
  paymentDateNew: 'Дата оформления',
  payUpTo: 'Выплатим до',
  changeMap: 'Сменить карту',
  cancelSubscription: 'Отменить подписку',
  subscription: 'Подписка',
  rate: 'Тариф',
  underProtection: 'Под защитой',
  startRate: 'Дата начала действия тарифа',
  rates: 'Тарифы',
  yourRate: 'Ваш тариф',
  firstPaymentDate: 'Дата первого платежа',
  youReallyUnsubscribe: 'Вы действительно хотите отменить подписку?',
  yourMark: 'Ваша оценка очень поможет нам в улучшении наших сервисов',
  yourCardDebited: 'С вашей карты спишется 10 ₸, а потом вернется назад на карту',
  good: 'Хорошо',
  cancel: 'Отмена',
  info: 'Инфо',
  goTariff: 'Перейти',

  canceledSubscription_1: 'Подписка успешно отменена',
  canceledSubscription_2: 'Возобновить подписку вы можете в личном кабинете',
  paymentCardSuccessfully_1: 'Платёжная карта успешно изменена',
  paymentCardSuccessfully_2: 'Теперь платежи будут списываться с этой карты',
  tariffChanged_1: 'Тариф успешно изменён',
  tariffChanged_2: 'Тариф начнёт действовать с ',
  enterValidLicensePlate: 'Введите номер в формате A000AAA или 000AAA00',
  noResultForSelect: 'Не верно указаны данные'
}