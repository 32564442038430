<template>
<without-header-layout>
  <ValidationObserver ref="form" v-slot="{}">
    <div class="form-group py-3">
      <div class="mb-4" v-if="!is_sent">
        <h4 class="text-center fw-bold"> Уважаемый клиент! <br>  Возникли неполадки, оставьте отзыв</h4>
      </div>
      <div class="mb-4" v-if="is_sent">
        <h4 class="text-center fw-bold"> {{$t('mediumFeedback')}}</h4>
      </div>
      <div class="feedback" v-if="!is_sent">
        <p class="text-center mb-4">{{$t('comments')}}</p>
        <textarea class="textarea" :placeholder="$t('write')" v-model="form.comment"></textarea>
      </div>
      <button class="primary-btn bottom-page-btn" @click="submit" :disabled="isButtonDisabled" v-if="!is_sent">>Отправить</button>
    </div>
  </ValidationObserver>
</without-header-layout>
</template>

<script>
import RatingStars from "../components/RatingStars";
import FeedbackResultModal from "../components/Modals/FeedbackResultModal";
import eventLogger from '../services/eventLogger';
import WithoutHeaderLayout from "../layouts/WithoutHeaderLayout";
export default {
  name: "SosFeedback",
  // eslint-disable-next-line vue/no-unused-components
  components: {WithoutHeaderLayout, RatingStars, FeedbackResultModal},
  data() {
    return {
      is_sent:false,
      form: {
        rating: null,
        comment: null,
        product_id: null
      },
      isButtonDisabled: true
    }
  },
  methods: {
    getRating(val) {
      this.form.rating = val;
    },
    redirect() {
      this.$router.push('/chat-navigation');
    },
    async submit() {
      try {
        this.form.rating = 0
        let success = await this.$refs.form.validate()
        if (success) {
          await window.axios.post('/feedback', this.form);
          this.is_sent = true
        }
        eventLogger('feedback', {feedback_text: this.form.comment, rating: this.form.rating})
      } catch(e) {
        throw e
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        if(val.comment) {
          this.isButtonDisabled = false;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
.textarea {
  height: 120px;
  margin-bottom: 24px;
}
}
.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip {
  width: 40px;
  height: 4px;
}

</style>
