export default {
    namespaced:true,
    state: {
        form: null,
        packet: null,
        amount: null
    },
    mutations: {
        "FILL_FORM"(state, payload) {
            state.form = payload;
        },
        "FILL_PACKET"(state, payload) {
            state.packet = payload;
        },
        "FILL_AMOUNT"(state, payload) {
            state.amount = payload;
        }
    },
    actions: {

    },
    getters: {
        finalFormValue(state) {
            return state.form
        },
        packet: state => state.packet,
        amount: state => state.amount,
    }
}
