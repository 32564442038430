import moment from "moment";
import _ from "lodash";
import {uuidv4} from "../../plugins/Helpers";

export default {
    namespaced: true,
    state: {
      draftId: uuidv4(),
      phoneNumber: null,
      startDate: moment().add(1, "days").format("YYYY-MM-DD"),
      period: 12,
      email: null,
      reward: 100000,
      holder: null,
      insureds: [],
      vehicle: null,
      creationTime: moment(),
      bonusId: null
    },
    mutations: {
        "SET_PHONE_NUMBER": (state, payload) => state.phoneNumber = payload,
        "SET_PERIOD": (state, payload) => state.period = payload,
        "SET_START_DATE": (state, payload) => state.startDate = payload,
        "SET_EMAIL": (state, payload) => state.email = payload,
        "ADD_CLIENT": (state, payload) => state.clients.push(payload),
        "REMOVE_CLIENT": (state, index) => state.clients.splice(index, 1),
        "SET_REWARD": (state, payload) => state.reward = payload,
        "CHANGE_HOLDER": (state, payload) => state.holder = payload,
        "SET_CHECK_HOLDER": (state, payload) => state.holder.checked = payload,
        "SET_HOLDER_CHECK_MESSAGE": (state, payload) => state.holder.checkMessage = payload,
        "ADD_INSURED": (state, payload) => state.insureds.push(payload),
        "CHANGE_INSURED": (state, {item, index}) => { state.insureds[index] = item },
        "REMOVE_INSURED": (state, index) => state.insureds.splice(index, 1),
        "CHANGE_BONUS_ID": (state, payload) => state.bonusId = payload,
        "RESET_CHEER_UP_FORM": (state) => {
            state.draftId = uuidv4()
            state.phoneNumber = null
            state.startDate = moment().add(1, "days").format("YYYY-MM-DD")
            state.email = null
            state.holder = { individual_id_number: ''}
        }
    },
    getters: {
        phoneNumber: state => state.phoneNumber,
        cars: state => state.cars,
        email: state => state.email,
        startDate: state => state.startDate,
        endDate: (state, getters) =>  { const { period, startDate } = getters; return moment(startDate).add(period, "M").subtract(1, "days").format('YYYY-MM-DD'); },
        period: state => state.period,
        reward: state => state.reward,
        holder: state => state.holder,
        insureds: state => state.insureds,
        vehicle: state => state.vehicle,
        bonusId: state => state.bonusId,
        finalFormValue: (state, getters, root) => {
            return {
              draft_id: state.draftId,
              phone_number: root.auth.authUser.phone_number,
              insurance_type: root.policyForm?.insuranceProduct?.insurance_type || '6611',
              holder: state.holder,
              start_date: state.startDate,
              bonus_id: getters.bonusId,
              source: 'mobile'
            }
        }
    },
    actions: {
        changePhoneNumber: ({commit, dispatch}, newPhoneNumber) => {
            dispatch('saveDraft')
            commit('SET_PHONE_NUMBER', newPhoneNumber)
        },
        changeReward: ({commit, dispatch}, newReward) => {
            dispatch('saveDraft')
            commit('SET_REWARD', newReward)
        },
        changeStartDate: ({commit, dispatch}, startDate) => {
            dispatch('saveDraft')
            commit('SET_START_DATE', startDate)
        },
        changeHolder: ({ commit, dispatch }, {item}) => {
            dispatch('saveDraft')
            commit('CHANGE_HOLDER', item);
        },
        changeInsured: ({ commit, dispatch }, {item, index}) => {
            if(item.individual_id_number) {
                dispatch('saveDraft')
            }
            commit("CHANGE_INSURED", { item, index });
        },
        addInsured({commit, dispatch}, payload) {
            dispatch('saveDraft')
            commit('ADD_INSURED', payload);
        },
        addNewInsured({commit, dispatch}) {
            dispatch('saveDraft')
            commit('ADD_INSURED', {
                individual_id_number: null,
                gender: null,
                doc_type: null,
                doc_number: null,
                doc_issue_date: null,
                address_region: null,
                address_state: null,
                address_city: null,
                address_street_full: null,
                address_street_no: null,
                address_flat_no: null,
            })
        },
        removeInsured({commit, dispatch}, index) {
            dispatch('saveDraft')
            commit("REMOVE_INSURED", index)
        },
        changeSelectedBonus: ({ commit, dispatch }, bonusID) => {
          dispatch('saveDraft')
          commit('CHANGE_BONUS_ID', bonusID);
        },
        resetForm({commit}) {
            commit("RESET_CHEER_UP_FORM")
        },

        saveDraft: _.debounce(({getters}) => {
            window.axios.post('/policy-draft', getters.finalFormValue)
        }, 400),
        saveDraftWithOtp: async ({getters}, code) => {
            await window.axios.post('/policy-draft', { ...getters.finalFormValue, code })
        },
    }
}
