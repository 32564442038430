import { getAnalytics, logEvent } from "firebase/analytics";
import amplitude from "amplitude-js";

export default async (event_name, payload = {}) => {
    const analytics = getAnalytics();

    logEvent(analytics, event_name, payload);

    amplitude.getInstance().logEvent(event_name, payload);
}
