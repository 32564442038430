export default {
    state: {
        flatOnlineForm:  null,
        myHouseForm: null,
        mostImportant: null,
        travelerForm: null,
        cheerUpForm: null,
        cascoLiteForm: null,
        ogpoForm: null,
        ogpoCars: null,
        ogpoReinsuranceForm: null,
        ogpoReinsuranceCars: null,

    },
    mutations: {
        SET_FLAT_ONLINE_FORM(state, payload) {
            state.flatOnlineForm = payload;
        },
        SET_MY_HOUSE_FORM(state, payload) {
            state.myHouseForm = payload;
        },
        SET_MOST_IMPORTANT_FORM(state, payload) {
            state.mostImportant = payload;
        },
        SET_TRAVELER_FORM(state, payload) {
            state.travelerForm = payload;
        },
        SET_CHEER_UP_FORM(state,payload) {
            state.cheerUpForm = payload;
        },
        SET_CASCO_LITE_FORM(state, payload) {
            state.cascoLiteForm = payload;
        },
        SET_OGPO_FORM(state, payload) {
            state.ogpoForm = payload;
        },
        SET_OGPO_CARS(state, payload) {
            state.ogpoCars = payload;
        },
        SET_OGPO_REINSURANCE_FORM(state, payload) {
            state.ogpoReinsuranceForm = payload;
        },
        SET_OGPO_REINSURANCE_CARS(state, payload) {
            state.ogpoReinsuranceCars = payload;
        },
    },
    actions: {
        getFlatOnlineForm({commit}, form) {
            commit("SET_FLAT_ONLINE_FORM", form)
        },
        getMyHouseForm({commit}, form) {
            commit("SET_MY_HOUSE_FORM", form)
        },
        getMostImportantForm({commit}, form) {
            commit("SET_MOST_IMPORTANT_FORM", form)
        },
        getTravelerForm({commit}, form) {
            commit("SET_TRAVELER_FORM", form)
        },
        getCheerUpForm({commit}, form) {
            commit("SET_CHEER_UP_FORM", form)
        },
        getCascoLiteForm({commit}, form) {
            commit("SET_CASCO_LITE_FORM", form)
        },
        getOgpoForm({commit}, form) {
            commit("SET_OGPO_FORM", form)
        },
        getOgpoCars({commit}, form) {
            commit("SET_OGPO_CARS", form)
        },
        getOgpoReinsuranceForm({commit}, form) {
            commit("SET_OGPO_REINSURANCE_FORM", form)
        },
        getOgpoReinsuranceCars({commit}, form) {
            commit("SET_OGPO_REINSURANCE_CARS", form)
        },
    },
    getters: {
        storedFlatOnlineForm(state) {
            return state.flatOnlineForm;
        },
        storedMyHouseForm(state) {
            return state.myHouseForm;
        },
        storedMostImportantForm(state) {
            return state.mostImportant;
        },
        storedTravelerForm(state) {
            return state.travelerForm;
        },
        storedCheerUpForm(state) {
            return state.cheerUpForm;
        },
        storedCascoLiteForm(state) {
            return state.cascoLiteForm;
        },
        storedOgpoForm(state) {
            return state.ogpoForm;
        },
        storedOgpoCars(state) {
            return state.ogpoCars;
        },
        storedOgpoReinsuranceForm(state) {
            return state.ogpoReinsuranceForm;
        },
        storedOgpoReinsuranceCars(state) {
            return state.ogpoReinsuranceCars;
        },
    }
};
