<template>
  <div class="secondary-page">
    <div class="secondary-header" :class="{ 'header-hide': !showNavbar }">
      <a @click="goBack" class="back-btn" v-if="backBtn">
        <icon icon-name="BackArrowIcon"></icon>
        <span class="fw-bold ms-4" v-if="toHomePage">{{$t('backToMain')}}</span>
      </a>
      <h2 class="title mb-0" v-if="!toHomePage">{{ title }}</h2>
      <router-link class="info-link" v-if="infoLink && product" :to="{name: 'AboutProduct', params: {id: product.id, product: product}}">{{ $t('info') }}</router-link>
    </div>
    <div class="content container">
      <slot></slot>
    </div>
    <the-navigation v-if="displayedNavigation"/>
  </div>
</template>

<script>

import TheNavigation from "../components/TheNavigation";
export default {
  name: "SecondaryPageLayout",
  components: {TheNavigation},
  props: {
    title: {
      type: String
    },
    toHomePage: {
      type: Boolean
    },
    displayedNavigation: {
      type: Boolean
    },
    backBtn: {
      type: Boolean,
      default: true
    },
    infoLink: {
      type: Boolean,
    },
    product: {
      type: Object
    }
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0
    }
  },
  mounted() {
    // this.lastScrollPosition = window.pageYOffset
    // window.addEventListener('scroll', this.onScroll)
    // const viewportMeta = document.createElement('meta')
    // viewportMeta.name = 'viewport'
    // viewportMeta.content = 'width=device-width, initial-scale=1'
    // document.head.appendChild(viewportMeta)
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    goBack() {
      if(this.toHomePage) {
        this.$router.push('/welcome');
      } else {
        this.$router.go(-2);
      }
    },
    onScroll() {
      const OFFSET = 60;
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    }
  }
}
</script>

<style lang="scss">
.secondary-page {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F7F7F7;
  .content {
    flex: 1;
    padding: 78px 0 16px;
    background: #F7F7F7;
  }
}
.secondary-header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background: #F7F7F7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;

  .back-btn {
    position: absolute;
    left: 16px;
    display: flex;
    align-items: center;
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
}
.header-hide {
  transform: translate3d(0, -100%, 0);
}
.secondary-header{
  .info-link{
    position: absolute;
    right: 16px;
    color: #2AA65C;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
