export default {
  state: {
    insuranceProduct: null
  },
  mutations: {
    "SET_INSURANCE_PRODUCT": (state, payload) => { state.insuranceProduct = payload },
  },
  getters: {
    INSURANCE_PRODUCT: state => state.insuranceProduct
  },
};
