import Vue from "vue";

import VModal from "vue-js-modal";
import Vue2TouchEvents from "vue2-touch-events";
import PortalVue from "portal-vue";
import VueI18n from 'vue-i18n'

import App from "./App.vue";
import "./registerServiceWorker";
import "./bootstrap";
import "/src/sass/app.scss";
import { translations } from "./translations";
import OtpInput from "@bachdgvn/vue-otp-input";

import router from "./router";
import { routerHandlerInit } from "./handlers/routerHandler";
import store from "./store";

import Helpers from "./plugins/Helpers";
import DatePicker from "./plugins/date-picker";
import InputMask from "./plugins/input-mask";
import LaravelValidator from "./plugins/laravel-validator";

import { ValidationProvider, extend, ValidationObserver } from "vee-validate";

import * as rules from "vee-validate/dist/rules";

import { messages } from "vee-validate/dist/locale/en.json";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

//traefik enable

/*
* Vue Select
* https://github.com/sagalbot/vue-select
* */

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';


// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB5v4meu6hmaXBJSpExPiKwUv0bttQhClM",
  authDomain: "halyk-sk-9deb8.firebaseapp.com",
  projectId: "halyk-sk-9deb8",
  storageBucket: "halyk-sk-9deb8.appspot.com",
  messagingSenderId: "323908141044",
  appId: "1:323908141044:web:2f34be6c2821b39ce583a1",
  measurementId: "G-HCRN6S4VWZ"
};

// Initialize Firebase
initializeApp(firebaseConfig);

/**
 * Import GTM
* */
import './gtm'

/**
* Настройка amplitude
* */

import amplitude from 'amplitude-js';

amplitude.getInstance().init('c75f64dc61ab904b414c9c1230c97fee');
amplitude.getInstance().setUserId(store?.getters?.authUser?.id)

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

Vue.use(VueLoading, {
  color: "#2AA65C",
  opacity: 1
});
Vue.use(PortalVue);
Vue.use(VModal, {dialog: true});
Vue.use(Vue2TouchEvents);
Vue.use(InputMask);
Vue.use(Helpers);
Vue.use(LaravelValidator);
Vue.use(VueI18n);

routerHandlerInit(store, router);

Vue.component("v-otp-input", OtpInput);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.component("default-layout", require("@/layouts/DefaultLayout").default);
Vue.component("date-picker", DatePicker);
Vue.component(
  "secondary-page-layout",
  require("@/layouts/SecondaryPageLayout").default
);
Vue.component("modal-layout", require("@/layouts/ModalLayout").default);
Vue.component("icon", require("@/components/Icons/Icon").default);

const translate = Object.assign(translations)

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'ru',
  messages: translate
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
