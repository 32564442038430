import {i18n} from "../../main";

export default {
    state: {
        selectedLang: localStorage.getItem('lang') || 'ru'
    },
    mutations: {
        SET_LANG(state, payload) {
            state.selectedLang = payload;
            localStorage.setItem('lang', state.selectedLang);
        }
    },
    actions: {
        getLanguage({commit}, lang_id) {
            switch (lang_id) {
              case 'rus':
                lang_id = 'ru';
                break;
              case 'kaz':
                lang_id = 'kk';
                break;
              case 'eng':
                lang_id = 'en';
                break;
              default:
                lang_id = 'ru';
                break;
            }
            if (lang_id) {
              commit("SET_LANG", lang_id)
              i18n.locale = lang_id
              window.axios.defaults.headers.common['Content-Language'] = lang_id
            }
        }
    },
    getters: {
        selectedLanguage(state) {
            return state.selectedLang;
        },
        getTranslationFieldSuffix(state) {
          return state.selectedLang === "ru" ? '' : `_${state.selectedLang}`;
        },

        getAltTranslationFieldSuffix(state) {
          const suffix = state.selectedLang === "ru" ? '' : `_${state.selectedLang}`;

          if(suffix === '_kk'){
            return '_kz';
          }

          return suffix;

        }
    }
};
