import Vue from 'vue'
import Vuex from 'vuex'
import policyForm from './modules/policyForm'
import info from "./modules/info";
import list from "./modules/list";
import auth from "./modules/auth";
import aboutUsGetCity from "./modules/aboutUsGetCity";
import lang from "./modules/lang";
import storePolicyForms from "./modules/storePolicyForms";
import ogpoForm from './modules/ogpoForm';
import cascoForm from './modules/cascoForm';
import flatForm from './modules/flatForm';
import mostImportantForm from "./modules/mostImportantForm";
import cheerUpForm from "./modules/cheerUpForm";
import travelerForm from "./modules/travelerForm"
import ogpoExtension from "./modules/ogpoExtension"
import cascoSubs from "./modules/cascoSubs";
import flatSubs from "./modules/flatSubs";
import houseSubs from './modules/houseSubs'
import reinsuranceForm from './modules/reinsuranceForm'
import dictionary from "./modules/dictionary";
import createPersistedState from 'vuex-persistedstate';
import myHouseForm from "./modules/myHouseForm";
import moment from "moment";
import {uuidv4} from "../plugins/Helpers";
import itemSubscription from "./modules/itemSubscription";
import agreements from "./modules/agreements";
import agreementsArchive from "./modules/agreementsArchive";

const persistedPaths = [
  'ogpoForm',
  'cascoForm',
  'cascoSubs',
  'cheerUpForm',
  'flatForm',
  'flatSubs',
  'myHouseForm',
  'houseSubs',
  'mostImportantForm',
  'policyForm',
  'agreements',
  'agreementsArchive',
  // 'auth',
  'avn',
  'os'
]


Vue.use(Vuex)

/* todo session storage or local storage */

export default new Vuex.Store({
  modules: {
    auth,
    policyForm,
    info,
    list,
    aboutUsGetCity,
    lang,
    storePolicyForms,
    ogpoForm,
    cascoForm,
    flatForm,
    mostImportantForm,
    cheerUpForm,
    myHouseForm,
    reinsuranceForm,
    travelerForm,
    dictionary,
    agreements,
    agreementsArchive,
    ogpoExtension,
    itemSubscription,
    cascoSubs,
    flatSubs,
    houseSubs
  },
  state: {
    avn: null,
    os: null,
  },
  getters: {
    hbUrl: () => 'https://homebank.kz/partners/halyksk?url=',
    appUrl: () => process.env.VUE_APP_URL,
    avn: state => state.avn,
    os: state => {
      if(state.os !== null) {
        return state.os
      } else {
        if(window.Android) {
          return 'Android';
        } else if(window.webkit) {
          return 'iOS';
        } else {
          return null
        }
      }
    },
    isNewVersionHB: state => {
      if(state.os !== null && state.avn !== null) {
        return true;
      }
      return false;
    }
  },
  mutations: {
    setAVN(state, payload) {
      state.avn = payload
    },
    setOs(state, payload) {
      state.os = payload
    }
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: persistedPaths,
      reducer(val, paths) {
        // make persisted state as in paths
        let reducer = {};

        if((val.auth.accessToken === null || val.auth.authUser === null)) {
          if(val.os && val.avn) {
            return { os: val.os, avn: val.avn}
          }
        }

        Object.entries(val).forEach(entry => {
          const [key, value] = entry;

          if (paths.includes(key)) {
            const now = moment();
            const diff = now.diff(value?.creationTime) / 1000;

            let formReducer = value;

            if(diff > 1200) {
              formReducer = {
                draftId: uuidv4(),
                creationTime: now
              };
            }

            reducer[key] = formReducer;
          }
        });

        return reducer;
      }
    })
  ]
})
