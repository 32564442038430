import moment from "moment";
import _ from "lodash";
import {uuidv4} from "../../plugins/Helpers";

export default {
  namespaced: true,
  state: {
    draftId: uuidv4(),
    phoneNumber: null,
    holder: null,
    startDate: moment().add(1, "days").format("YYYY-MM-DD"),
    period: 12,
    email: null,
    owner_is_holder: true,
    property_owner: null,
    insured_property: {
      country: "KAZ",
      state: null,
      region: null,
      city: null,
      street_name: null,
      street_no: null,
      flat_no: null,
    },
    creationTime: moment(),
    bonusId: null,
    tariff: null,
  },
  getters: {
    phoneNumber: state => state.phoneNumber,
    holder: state => state.holder,
    ownerIsHolder: state => state.owner_is_holder,
    propertyOwner: state => state.property_owner,
    period: state => state.period,
    email: state => state.email,
    startDate: state => state.startDate,
    endDate: (state, getters) =>  { const { period, startDate } = getters; return moment(startDate).add(period, "M").subtract(1, "days").format('YYYY-MM-DD'); },
    insuredProperty: state => state.insured_property,
    bonusId: state => state.bonusId,
    tariff: state => state.tariff,
    finalFormValue: (state, getters, root) => {
      return {
        draft_id: state.draftId,
        phone_number: root.auth.authUser.phone_number,
        insurance_type: root.policyForm?.insuranceProduct?.insurance_type || 3016,
        holder: state.holder,
        start_date: state.startDate,
        owner_is_holder: state.owner_is_holder,
        property_owner: state.property_owner,
        insured_property: state.insured_property,
        bonus_id: getters.bonusId,
        tariff: getters.tariff,
        source: 'mobile'
      }
    },
    finalSubscriptionFormValue: (state, getters, root) => {
      return {
        draft_id: state.draftId,
        phone_number: root.auth.authUser.phone_number,
        insurance_type: 3016,
        holder: state.holder,
        start_date: state.startDate,
        owner_is_holder: state.owner_is_holder,
        property_owner: state.property_owner,
        insured_property: state.insured_property,
        tariff: getters.tariff,
        man_id: 2,
        source: 'mobile'
      }
    },
  },
  mutations: {
    "SET_PHONE_NUMBER": (state, payload) => state.phoneNumber = payload,
    "SET_PERIOD": (state, payload) => state.period = payload,
    "SET_START_DATE": (state, payload) => state.startDate = payload,
    "SET_EMAIL": (state, payload) => state.email = payload,
    "SET_HOLDER": (state, payload) => state.holder = payload,
    "SET_TARIFF": (state, payload) => state.tariff = payload,
    "SET_PROPERTY_OWNER": (state, payload) => state.property_owner = payload,
    "SET_OWNER_IS_HOLDER": (state, payload) => state.owner_is_holder = payload,
    "SET_INSURED_PROPERTY": (state, payload) => state.insured_property = payload,
    "SET_CHECK_HOLDER": (state, payload) => state.holder.checked = payload,
    "SET_HOLDER_CHECK_MESSAGE": (state, payload) => state.holder.checkMessage = payload,
    "SET_CHECK_OWNER": (state, payload) => state.property_owner.checked = payload,
    "SET_OWNER_CHECK_MESSAGE": (state, payload) => state.property_owner.checkMessage = payload,
    "CHANGE_BONUS_ID": (state, payload) => state.bonusId = payload,
    "RESET_MY_HOUSE_FORM": (state) => {
      state.draftId = uuidv4()
      state.phoneNumber = null
      state.startDate = moment().add(1, "days").format("YYYY-MM-DD")
      state.email = null
      state.holder = { individual_id_number: ''},
          state.insured_property = {
            country: "KAZ",
            state: null,
            region: null,
            city: null,
            street_name: null,
            street_no: null,
            flat_no: null,
          }
    }
  },
  actions: {
    changeTariff: ({commit, dispatch}, newTariff) => {
      dispatch('saveDraft')
      console.log('newTariff > ', newTariff);
      commit('SET_TARIFF', newTariff)
    },
    changePhoneNumber: ({commit, dispatch}, newPhoneNumber) => {
      dispatch('saveDraft');
      commit('SET_PHONE_NUMBER', newPhoneNumber)
    },
    changeStartDate: ({commit, dispatch}, startDate) => {
      dispatch('saveDraft');
      commit('SET_START_DATE', startDate)
    },
    // payload data Object: { item, index }
    changeHolder: ({ commit, dispatch}, { item }) => {
      dispatch('saveDraft');
      commit('SET_HOLDER', item);
    },
    changePropertyOwner: ({ commit, dispatch }, { item }) => {
      dispatch('saveDraft');
      commit('SET_PROPERTY_OWNER', item);
    },
    changeOwnerIsHolder: ({commit, dispatch}, payload) => {
      dispatch('saveDraft');
      commit("SET_OWNER_IS_HOLDER", payload)
    },
    changeInsuredProperty: ({commit, dispatch}, payload) => {
      dispatch('saveDraft');
      commit("SET_INSURED_PROPERTY", payload)
    },
    changeSelectedBonus: ({ commit, dispatch }, bonusID) => {
      dispatch('saveDraft')
      commit('CHANGE_BONUS_ID', bonusID);
    },
    saveDraft: _.debounce(({getters}) => {
      window.axios.post('/policy-draft', {...getters.finalFormValue })
    }, 400),
    saveDraftWithOtp: async ({getters}, code) => {
      await window.axios.post('/policy-draft', { ...getters.finalFormValue, code })
    },
    resetForm({commit}) {
      commit("RESET_MY_HOUSE_FORM")
    },
  }
}
