export default {
    namespaced:true,
    state: {
        loadingAgreements: false,
        loadingSubs: false,
        loadingLife: false,
        agreements: [],
        subscriptions: [],
        lifeAgreements: [],
        policies: []
    },
    mutations: {
        setloadingAgreements(state, isLoading) {
            state.loadingAgreements = isLoading;
        },
        setloadingSubs(state, isLoading) {
            state.loadingSubs = isLoading;
        },
        setloadingLife(state, isLoading) {
            state.loadingLife = isLoading;
        },
        setArcAgreements (state, agreements) {
            state.agreements = agreements
        },
        setArcSubscriptions (state, subscriptions) {
            state.subscriptions = subscriptions
        },
        setArcLifeAgreements (state, lifeAgreements) {
            state.lifeAgreements = lifeAgreements
        },
        setArcPolicies (state, policies) {
            state.policies = policies
        },
    },
    actions: {
        async getArchiveLifeAgreements({commit}) {

            try {
                const {data} = await window.axios.get('life/policies/archive');
                commit('setArcLifeAgreements', data.policies || [])
            } catch (e) {
                throw e
            }finally {
                commit('setloadingLife', false);
            }
        },

        async getArchiveAgreementsSubscription({commit}) {
            commit('setloadingSubs', true);
            try {
                const {data} = await window.axios.get('subscription/archive');
                commit('setArcSubscriptions', data)
            } catch (e) {
                throw e
            } finally {
                commit('setloadingSubs', false);
            }
        },

        async getArchiveAgreements({commit}) {
            commit('setloadingAgreements', true);
            try {
                const {data} = await window.axios.get('policies/archive');
                commit('setArcAgreements', data)
            } catch (e) {
                throw e
            }finally {
                commit('setloadingAgreements', false);
            }
        },

        async getArchivePolicies({commit}) {
            console.log('=========ENTER========')
            const {data} = await window.axios.get('insurance-types/all')
            commit('setArcPolicies', data)
        }
    },
    getters: {

    }
}
