import moment from "moment";

import {
  changePhoneNumber, changeSelectedProductId, changeSelectedProductName, deletePerson, deleteCar,
  getPeopleByIin, getCarByLicensePlate, getProducts, setProducts, isHaveBasketFriend,
  getCascoLite, getFixSum, getFixSumByProduct, setTempPolicy, sumVerification, sendOTP,
  verifyOTP, sendErrorsToRedmine, saveNotificationsToRedmine, checkClientsDoc, setAmountLoaded, fetchAvailableCars
} from "../helpers/ogpoFunctions";

import {
  clientsChecked,
  selectedProductId,
  selectedProductName,
  availableProducts,
  autoplusProduct,
  isLoyalClient,
  minRiskRating,
  carYearAndCarType,
  productsRegion,
  isStandard,
  noBasketRegions, amountLoaded
} from "../helpers/ogpoGetters";
import {uuidv4} from "../../plugins/Helpers";
import _ from "lodash";

export default {
    namespaced: true,
    state: {
      draftId: uuidv4(),
      phoneNumber: null,
      clients: [],
      cars: [],
      beginDate: moment().add(1, "days").format("YYYY-MM-DD"),
      period: 12,
      email: null,
      clientIin:null,
      carNumber: null,
      autoplus: false,
      selectedProductId: 0,
      selectedProductName: "",
      friendBasket: false,
      discountBool: false,
      invoiceId: null,
      homebankToken: null,
      products: [],
      calculatedSum: {},
      baseSumm: 0,
      totalSum: 0,
      afterDicsountSumm: 0,
      otpKey: null,
      otpCode: false,
      allProducts: null,
      isClient: true,
      cascoSelected: false,
      cascoOptions: [],
      cascoModel: [],
      cascoReward: 0,
      errorMessage: '',
      addSumError: null,
      creationTime: moment(),
      amountLoaded: false,
      availableCars: []
    },
    mutations: {
      "SET_PHONE_NUMBER": (state, payload) => { state.phoneNumber = payload  },
      "SET_PERIOD": (state, payload) => { state.period = payload; },
      "SET_BEGIN_DATE": (state, payload) => { state.beginDate = payload; },
      "SET_EMAIL": (state, payload) => { state.email = payload; },
      "ADD_CLIENT": (state, payload) => { state.clients.push(payload) },
      "REMOVE_CLIENT": (state, index) => { state.clients.splice(index, 1); },
      "ADD_CAR": (state, payload) => { state.cars.push(payload) },
      "REMOVE_CAR": (state, index) => { state.cars.splice(index, 1) },
      "SET_SELECTED_PRODUCT_ID": (state, productId) => { state.selectedProductId = parseInt(productId) },
      "SET_SELECTED_PRODUCT_NAME": (state, name) => { state.selectedProductName = name },
      "SET_PRODUCTS": (state, payload) => { state.products = payload },
      "SET_FRIEND_BASKET": (state, payload) => { state.friendBasket = payload },
      "SET_BASESUMM": (state, payload) => { state.baseSumm = payload },
      "SET_CALCULATED_SUM": (state, payload) => { state.calculatedSum = JSON.parse(JSON.stringify(payload)) },
      "SET_CASCO_LITE_SELECTED": (state, payload) => { state.cascoSelected = payload },
      "SET_CASCO_LITE_OPTIONS": (state, payload) => { state.cascoOptions = payload },
      "SET_CASCO_LITE_REWARD": (state, payload) => { state.cascoReward = payload },
      "SET_ADDSUMERROR": (state, payload) => state.addSumError = payload,
      "SET_INVOICEID": (state, payload) => state.invoiceId = payload,
      "SET_OTPKEY": (state, payload) => state.otpKey = payload,
      "SET_OTPCODE": (state, payload) => state.otpCode = payload,
      "SET_AMOUNT_LOADED": (state, payload) => state.amountLoaded = payload,
      "SET_AVAILABLE_CARS": (state, payload) => state.availableCars = payload,
      "REPLACE_CLIENT": (state, payload) => {
        state.clients.splice(payload.index, 1, payload.client)
      }
    },
    getters: {
      phoneNumber: state => state.phoneNumber,
      clients: state => state.clients,
      cars: state => state.cars,
      email: state => state.email,
      beginDate: state => state.beginDate,
      endDate: (state, getters) => {
        const {period, beginDate} = getters;
        return moment(beginDate).add(period, "M").subtract(1, "days").format('YYYY-MM-DD');
      },
      calculatedSum: state => state.calculatedSum,
      period: state => state.period,
      casco: state => state.casco,
      products: state => state.products,
      allProducts: state => state.products,
      friendBasket: state => state.friendBasket,
      baseSumm: state => state.baseSumm,
      cascoSelected: state => state.cascoSelected,
      cascoOptions: state => state.cascoOptions,
      cascoReward: state => state.cascoReward,
      addSumError: state => state.addSumError,
      invoiceId: state => state.invoiceId,
      homebankToken: state => state.homebankToken,
      otpKey: state => state.otpKey,
      otpCode: state => state.otpCode,
      availableCars: state => state.availableCars,
      allVerified: state => {
        let allVerified = 1
        if(state.clients.some(item => item.verifyType === 0) || state.clients.some(item => item.verifyType === 0)) {
          allVerified = 0
        }
        return allVerified;
      },
      amountLoaded,
      clientsChecked,
      selectedProductId,
      selectedProductName,
      availableProducts,
      autoplusProduct,
      isLoyalClient,
      minRiskRating,
      carYearAndCarType,
      productsRegion,
      noBasketRegions,
      isStandard,
      finalFormValue: (state, getters, root) => {
        return {
          draft_id: state.draftId,
          phone_number: root.auth.authUser.phone_number,
          insurance_type: 1000,
          clients: getters.clients.map((client) => ({individual_id_number: client.iin, full_name: client.fullName})),
          start_date: getters.beginDate,
          period: getters.period,
          end_date: getters.endDate,
          cars: getters.cars.map(car => ({ govNumber: car.govNumber, territory: car.territory })),
          selectedProductName: getters.selectedProductName,
          minRiskRating: getters.minRiskRating,
          calculatedSum: getters.calculatedSum,
          otpKey: getters.otpKey,
          otpCode: getters.otpCode,
          source: 'mobile'
        }
      }
    },
    actions: {
      changePhoneNumber,
      changeSelectedProductId,
      changeSelectedProductName,
      deletePerson,
      deleteCar,
      getPeopleByIin,
      getCarByLicensePlate,
      getProducts,
      setProducts,
      isHaveBasketFriend,
      getCascoLite,
      getFixSum,
      getFixSumByProduct,
      setTempPolicy,
      sumVerification,
      sendOTP,
      verifyOTP,
      sendErrorsToRedmine,
      saveNotificationsToRedmine,
      checkClientsDoc,
      setAmountLoaded,
      fetchAvailableCars,
      saveDraft: _.debounce(({ dispatch }) => {
        dispatch('saveWithoutDebounce');
      }, 1000),
      saveWithoutDebounce:  ({getters}) => {
        window.axios.post('/policy-draft', getters.finalFormValue)
      }
    },
}
