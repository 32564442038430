export default {
  main: 'Басты',
  goToMain: 'Басты бетке',
  success: 'Success',
  error: 'Error',
  //General
  goBack: 'Артқа',
  continueNext: 'Әрі қарай',
  confirmation: 'Растама',
  confirm: 'Растау',
  details: 'Толығырақ',
  cost: 'Құны',
  save: 'Сақтау',
  close: 'Жабу',
  send: 'Жөнелту',
  extend: 'Ұзарту',
  changePolicy: 'Полис өзгерту',
  cascoPriceLimit: 'Сақтандыру төлемдерінің шегі 100 000 тг.',
  cascoOnlyCars: 'Тек жеңіл автомобильдер',
  phoneNumber: 'Телефон нөмірі',
  enterPhoneNumber: 'Телефон нөмірін енгізіңіз',
  incorrectPhoneNumber: 'Телефон нөмірі дұрыс емес',
  fullName: 'ТАӘ',
  insurance: 'Сақтандыру',
  changeTariff: 'Тарифті өзгерту',

  insurancePolicy: 'Сақтандыру полисі',
  halykIC: 'Халық СК',
  selectedPayment: 'Таңдалған төлем',




  salary: 'Еңбекақы',
  agreementDate: 'Шарттың басталатын күні',
  noAgreements: 'Сіз әлі ештеңе сақтандырмадыңыз, бірақ бәрі әлі алда!',
  policyStartDate: 'Полистің басталу күні',
  bonusProduct: 'Бонустық өнім',
  upTo25percent: '25%-ға дейін бонус',
  agreedToInsuranceTerms: '"Халық" сақтандыру компаниясы" АҚ сақтандыру {0} және тарифтерімен келісемін.',
  readInsuranceTerms: ' Сақтандыру {0} таныспын.',
  declaration: 'декларациясымен',
  declarationTitle: 'Декларация',
  rules: 'ережесімен',
  apartmentInsurance: 'Мүлікті сақтандыру',
  buyPolicy: 'Полис сатып алу',

  loyalClientModalTitle: 'Уважаемый клиент',
  loyalClientModalText: 'Вам предоставлена дополнительная страховка  к полису ОГПО ВТС! Мы ценим наших постоянных клиентов.',
  dearClient: 'Уважаемый клиент',
  visitBranchToSign: 'Для переоформления договора необходимо обратиться в офис страховой компании',

  cantChangeNumber: 'Нет возможности поменять номер телефона. Для замены необходимо обратится в службу поддержки клиентов, по номеру - 797',

  //Main menu
  menu: 'Меню',
  products: 'Өнімдер',
  insuredCase: 'Сақтандыру жағдайы',
  aboutUs: 'Біз туралы',
  specialOffers: 'Науқандар',
  language: 'Тіл',
  pay: 'Төлеу',

  //Agreements tab
  agreements: 'Шарттар',
  effectiveAgreements: 'Қолданыстағы',
  expiredAgreements: 'Өтіп кеткен',
  myGifts: 'Менің сыйлығым',
  myMedCards: 'Мед карты',

  medInsureds: 'Cақтандырылған',
  medCenterAddresses: 'Медициналық мекемелердің мекен-жайлары',
  medPrograms: 'Медициналық бағдарлама',
  //Chat tab
  chat: 'Чат',
  writeToWhatsapp: 'WhatsApp-қа жазу',
  callUs: 'Бізге қоңырау шалу',
  chatUs: 'Бізге жазу (чат-бот)',
  getAPolicy: 'Полисті ресімдеу',
  leaveFeedback: 'Пікір қалдыру',
  support: 'Қолдау',

  //Payment tab
  paymentFailed: 'Төлем өткен жоқ',
  paymentSuccess: 'Төлем қабылданды',
  paymentReceipt: 'Төлем туралы квитанция және төлем мәртебесі тарихыңызда қолжетімді ',

  //About us tab
  halykTextBold: '"Халық" сақтандыру компаниясы',
  halykText: ' - заңды және жеке тұлғалардың барлық санаттарына сақтандыру қызметтерінің толық спектрін көрсететін әмбебап сақтандыру компаниясы. Қазақстандағы ірі қаржы тобы -  "Halyk Group" құрамына кіреді. Сақтандыру нарығында 25 жылдан астам уақыттан бері қызмет етеді.',
  address: 'Мекенжайы',
  phones: 'Телефондары',
  schedule: 'Жұмыс кестесі',
  goToHistory: 'Тарихқа өту',
  showOnMap: 'Картадан қарау',
  branches: ['болімше', 'болімшелер', 'болімшелер'],
  listOfBranches: 'Болімшелер',


  //Credit card
  cardNumber: 'Карта нөмірі',
  cardDate: 'АА/ЖЖ',
  payButton: 'Төлеу',

  //Languages tab
  langRus: 'Орысша',
  langKaz: 'Қазақша',
  langEng: 'Ағылшын',

  logout: 'Шығу',

  //Notifications tab
  notifications: 'Хабарландырулар',
  policyEnd: 'Полистің аяқталуы',
  specialOffer: 'Акция',
  kaskoLightOffer: 'KACKO Light ресімдеуге',
  markAllAsRead: 'Барлығын оқылды деп белгілеу',

  //Feedback tab
  feedback: 'Пікір',
  pleaseRateService: 'Біздің сервистің сапасын бағалауыңызды сұраймыз:',
  pleaseRateBonuses: 'Ұсынылған бонустар сабын бағалауыңызды сұраймыз:',
  pleaseRateManager: 'Менеджердің (болған кезде) жұмысын бағалауыңызды сұраймыз:',
  comments: 'Комментарийлер:',
  comment: 'Комментарий',
  write: 'Жазу...',

  //SosForm tab
  insuranceProduct: 'Сақтандыру өнімі',
  attachDocs: 'Құжаттарды тіркеңіз',
  uploadIDCard: 'Жеке куәліктің фотосы',
  IDCardFront: 'Алдыңғы жағы',
  IDCardBack: 'Кері жағы',
  otherDocs: 'Басқа құжаттар',
  addDocument: 'Құжат қосу',
  iin: 'ЖСН',
  sex: 'Жыныс',
  maxDocSize: 'Максималды өлшем 10мб',
  policyNumber: 'Шарттың нөмірі',
  //SosNavigation
  sosTitle: 'Сақтандыру оқиғасы қай өнім үшін болды?',
  sendRequest: 'Өтінім қалдыру',

  //Chat section
  askUs: 'Сұраңыз',

  //AddCarInfo
  addCarNumber: 'Автокөлік нөмірін енгізіңіз',

  //AddCascoLite
  addCascoBoth: 'КАСКО лайтты қосу (екіжақты)',
  premium: 'Сыйлықақы',
  insuranceLimit: 'Сақтандыру төлемінің лимиті',
  payAnotherCard: 'Басқа банктің картасымен төлеу',

  //AddCountry
  countryDestination: 'Сапарға шығу елу',
  addCountry: 'Елді қосыңыз',

  //AddPersonButton
  addInsured: 'Сақтандырушыны қосу',
  removeInsured: 'Сақтандырылушыны алып тастау',

  //AddPersonInfo
  enterIIN: 'ЖСН енгізу',
  mainInsureds:'Негізгі сақтанушы',
  enterLicenceCardNumber: 'Жүргізуші куәлігінің номерін енгізу',
  //CheckboxAndSelect
  addCascoLight: 'КАСКО лайтты қосу',
  cascoLight: 'КАСКО лайт',

  //CheckboxRadioInputList
  selectBonusProduct: 'Бонустық өнімді таңдаңыз',
  additionalProduct: 'Қосымша бонус',

  //CreditCardSelect
  addCard: 'Картаны қосу',
  newCard: 'Жана карта',
  //FinalAmount
  total: 'Жиынтығы',

  productRules: 'Сақтандыру ережелері',

  //SearchSelect
  nothingFound: 'Ештеңе табылған жоқ',
  loading: 'Жүктелу...',

  //SelectDate
  today: 'Бүгін',
  yesterday: 'Кеше',

  selectDate: 'Күнін таңдаңыз',
  jan: 'Қаңтар',
  feb: 'Ақпан',
  mar: 'Наурыз',
  apr: 'Сәуiр',
  may: 'Мамыр',
  jun: 'Маусым',
  jul: 'Шiлде',
  aug: 'Тамыз',
  sep: 'Қыркүйек',
  oct: 'Қазан',
  nov: 'Қараша',
  dec: 'Желтоқсан',

  janShort: 'Қаң',
  febShort: 'Ақп',
  marShort: 'Нау',
  aprShort: 'Сәу',
  mayShort: 'Мам',
  junShort: 'Мау',
  julShort: 'Шiл',
  augShort: 'Там',
  sepShort: 'Қыр',
  octShort: 'Қаз',
  novShort: 'Қар',
  decShort: 'Жел',

  sun: 'Жексенбi',
  mon: 'Дүйсенбi',
  tue: 'Сейсенбi',
  wed: 'Сәрсенбi',
  thu: 'Бейсенбi',
  fri: 'Жұма',
  sat: 'Сенбi',

  sunShort: 'Жек',
  monShort: 'Дүй',
  tueShort: 'Сей',
  wedShort: 'Сәр',
  thuShort: 'Бей',
  friShort: 'Жұм',
  satShort: 'Сен',

  sunMin: 'Жек',
  monMin: 'Дүй',
  tueMin: 'Сей',
  wedMin: 'Сәр',
  thuMin: 'Бей',
  friMin: 'Жұм',
  satMin: 'Сен',

  //GetInfoCar
  carModel: 'Авто үлгісі',
  carRegNumber: 'Автоның тіркелу нөмірі',

  //GetInfoPerson
  driverName: 'Жүргізушінің аты',

  //Modals
  terms: 'Шарттар',
  understand: 'Түсінікті',

  selectCity: 'Қаланы таңдаңыз',
  findMyCity: 'Өз қалаңызды табыңыз',

  //DocumentsListModal
  docList: 'Құжаттар тізімі',
  notAllDocs: 'Құжаттарды түгел салмауға болады, бұл ретте өтінім ұзағырақ қаралады:',
  photoOfID: 'Жеке бас куәлігінің фотосы',
  photoTechPassport: 'Зақымдалған көлік құралының техпастортының фотосы; МАИ материалдары (хаттама және ЖКО схемасы)',
  photoCourt: 'Сот қаулысының фотосы (сот шешімінен кейін салуға болады)',
  photoBankDetails: 'Төлем алушының банктік деректемелері туралы мәліметтер (фото, PDF немесе мәтіндік файл)',

  ifAggrieved: 'Өміріне немесе денсаулығына зиян келтірілген жәбірленушілер болған кезде, қосымша ұсынылатын құжаттар:',
  photoSick: 'Жарамсыздық парағының фотосы/ эпикриз және т.б.',
  photoFiscal: 'Фискалды, тауар түбіртегінің фотосы',
  photoDisability: 'Мүгедектік белгілеу туралы анықтаманың фотосы',
  photoDeath: 'Қайтыс болу туралы куәліктің фотосы (немесе нотариат растаған көшірмесі)',

  //FeedbackResultModal
  lowFeedback: 'Төмен деңгейлі сервис үшін кешірім сұраймыз, шара қолданамыз!',
  mediumFeedback: 'Пікіріңізге рахмет, жетіле түсеміз!',
  highFeedback: 'Пікіріңізге рахмет, біз жақсарамыз!',

  //SosResultModal
  carInsurance: 'Автоны сақтандыру',
  sosRequestSuccess: 'Өтінім қабылданды. Құрметті клиент! Жақын арада Сізбен хабарласамыз',

  //OtpVerify
  smsConfirm: 'Нөміріне жөнелтілген SMS-тегі кодты енгізіңіз',
  nextCode: 'Жаңа кодты кейін алуға болады',
  nextCodeSec: 'сек',
  sendCodeAgain: 'Кодты қайта жіберіңіз',

  //CascoLiteForm
  location: 'Орналасқан жері',
  city: 'Қала',
  street: 'Ықшамаудан/көше',
  building: 'Үй',
  apartment: 'Пәтер',
  docType: 'Құжат түрі',
  docIdConfirm: 'Жеке басын куәландыратын құжат',
  passportConfirm: 'Паспортные данные, серия и номер',
  docNumber: 'Құжаттың нөмірі',
  docGiveDate: 'Құжатты беру күні',
  kazID: 'ҚР куәлігі',
  passport: 'Төлқұжат',
  nonResidentPermit: 'Бейрезиденттің тұруға рұқсатнамасы',
  driverLicense: 'Жүргізуші куәлігі',
  male: 'Еркек',
  female: 'Әйел',
  birthCertificate: 'Туу туралы куәлік',

  //CovidProtect
  typeOfTrip: 'Сапар түрі',
  typeOfTripAddition: '*Сақтандырылушы бұл полисті таңдалған кезең ішінде бірнеше мәрте іскерлік сапар жағдайында пайдалана алады.',
  selectPackage: 'Пакетті таңдаңыз',
  destinationCountry: 'Сапарға шығу елу',
  selectInsuranceSum: 'Сақтандыру сомасын таңдаңыз',
  departureDate: 'Шығу күні',
  arrivalDate: 'Келу күні',
  insurancePeriod: 'Сақтандыру кезеңі',
  tripDuration: 'Сапардың ұзақтығы',

  insuranceCountryWarning: 'Сақтандыруды ресімдеу сәтінде сақтандырылушылардың ешқайсысы уақытша тоқтау елінде болмауы тиіс.',
  mainPolicyHolder: 'Негізгі сақтанушы',
  touristBirthDate: 'Туристің туған күні',
  lastNameInLatin: 'Латын әрпімен тегі',
  firstNameInLatin: 'Латын әрпімен аты',
  selectDocument: 'Құжатты таңдаңыз',
  removeTourist: 'Туристі алып тастау',
  addTourist: 'Туристі қосу',
  infoAbout: 'Туристің деректері ',
  ofTourist: '',

  //CovidProtectSports
  sportType: 'Спорт түрі',
  isStudentAthlete: 'Оқитын адам спортшы ма?',
  sports1: 'Бейсбол, софтбол, баскетбол, поло, акробатика, ауыр және жеңіл атлетика',
  sports2: 'Биатлон, бағдарлау, өрт-қолданбалы, ату, фехтование',
  sports3: 'Виндсерфинг, квадроцикл, судағы мотоциклді айдау',
  sports4: 'Тау шаңғысы, сноуборд тебу, фристаил, самбо, дзюдо, велоспорт, күрес, мотобол',
  sports5: 'Дайвинг, су асты аң аулау',
  sports6: 'Кик-боксинг, бокс, каратэ, қоян-қолтық ұрыс, тхэквандо, ушу, сумо, айкидо,кендо, кобудо, кунг-фу, кудо',
  sports7: 'Парашют спорты, парапланеризм, дельтапланеризм, рафтинг, сноуборд',
  sports8: 'Жүзу, синхронды жүзу, аэробика, ескек есу, үстел үсті теннисі, батут, кір спорты, городки, гольф',
  sports9: 'Бесайыс, гимнастика, триатлон, желкен, ат спорты, суға секіру',
  sports10: 'Мәнерлеп сырғанау, бобслей, шана, теннис, сквош, бадминтон, конькимен, шаңғымен сырғанау, судағы шаңғы',
  sports11: 'Хоккей, буер, футбол, регби, альпинизм, құзға өрмелеу, роллер, гандбол, күшпен үшсайыс, сафари',

  alreadyInCountryOfTempResidence: 'Сіз уақытша тұратын елдесіз бе?',
  policyHolderTheInsured: 'Сақтанушы сақтандырылушы болып табылады',

  singleTravel: 'Бір жолғы',
  multiTravel: 'Көп жолғы',
  franchise: 'Франшиза',
  resident: 'Резидент',
  nonResident: 'Бейрезидент',
  //durationsAndDates
  numDays120: '120 күн',
  numDays180: '180 күн',
  numDays30: '30 күн',
  numDays365: '365 күн',
  numDays60: '60 күн',
  numDays90: '90 күн',

  year1: '1 жыл',
  month1: '1 ай',
  month3: '3 ай',
  month6: '6 ай',
  month12: '12 ай',

  upTo180: '180 күнге дейін',
  upTo45: '45 күнге дейін',
  upTo90: '90 күнге дейін',
  upTo30: '30 күнге дейін',
  upTo10: '10 күнге дейін',

  order: ['екінші', 'үшінші', 'төртінші', 'бесінші'],

  tourist1info: "Бірінші туристің деректері",
  tourist2info: "Екінші туристің деректері",
  tourist3info: "Үшінші туристің деректері",
  tourist4info: "Төртінші туристің деректері",
  tourist5info: "Бесінші туристің деректері",

  //flatOnlineForm
  infoAboutInsuredProperty: 'Сақтандырылған мүлік туралы деректер',
  country: 'Ел',
  region: 'Өңір',
  district: 'Аудан',
  insuredAndPolicyHolderMatch: 'Сақтанушының және сақтандырылушының деректері сәйкес келеді',

  //OgpoForm
  drivers: 'Жүргізушілер',
  addDriver: 'Жүргізушіні қосу',
  cars: 'Көліктер',
  addCar: 'Көлік қосыңыз',
  emailNotNecessary: '\'Email\' толтыру міндетті емес',
  bonusProductWillAdded: 'Бонус картаға төлем жасалғаннан күннен 15-ші тәулікте жүктеледі.',
  yourAdditionalBonus: 'Сіздің қосымша бонусыңыз',
  driverAlreadyAdded: 'Бұл ЖСН бар жүргізуші қосылып қойған',
  carAlreadyAdded: 'Дәл осындай нөмірлі көлік қосылып қойған',
  serverError: 'Сервер қатесі',
  discount10: 'Жеңілдік 10%',
  autoplusCost: '"Автоплюс online" бағасы',
  productDiscount: 'Өнімге жеңілдік',
  bonusSum1: 'Бонус 25%',
  bonusSum2: 'Бонус 22%',
  bonusSum3: 'Бонус 20%',
  bonusSum4: 'Бонус 15%',
  bonusSum11: 'Бонус 30%',

  remainingSum: 'Қалдығы',

  //TravelerForm
  insuredIsEntitled: 'Сақтандырылушы бұл полисті таңдалған кезең ішінде бірнеше мәрте іскерлік сапар жағдайында пайдалана алады.',
  insuredSum: 'Сақтандыру сомасы',
  //SendGiftToFriend
  giftFriendInsurance: 'Досыңызға қосымша сақтандыру сыйлаңыз!',
  giftFriendNumber: 'Досыңыздың телефон нөмірін енгізіңіз, оған “Автоплюс online” қосымша бонусы бар смс келеді',
  giftFriendSent: 'Құрметті клиент! Қосымша сақтандыру досыңызға жөнелтілді.',
  giftFriendSmsSent: 'Досыңыздың ұялы нөміріне қосымшаға сілтемесі бар СМС жөнелтілді.',
  giftAlreadySent: "Сіз досыңызға сыйлық жіберіп қойдыңыз",
  alreadyRatedInvoice: 'Осы полис бойынша пікір бұрын сақталған',
  rateSaved: 'Пікір сақталды',
  //SecondaryPageLayout
  backToMain: 'Басты параққа',
  //PaymentNumberContract
  payContractTitle: 'Келісімшарт нөмірі бойынша төлеңіз',
  payContractPleaceH: 'Шарт саны',
  payContractBtn: 'Төлемге өтіңіз',
  payContractTitle_1: 'Өнім:',
  payContractTitle_2: 'Сақтандырушы:',
  payContractTitle_3: 'Сақтандырылған:',
  payContractTitle_4: 'Сақтандыру объектісі:',
  payContractTitle_5: 'Шарт ',
  payContractTitle_6: 'шарт жасасу ',
  payCard: 'Картамен төлеу',
  agreedChangeTariff: '«Халық» сақтандыру компаниясы» АҚ {0} сақтандыру және тарифтерімен келісемін. Мен {1} сақтандырумен таныспын',
  monthlyPayment: 'Ай сайынғы төлем',
  forThreeMonthPayment: 'Для Вас платеж на 3 мес',
  paymentDate: 'Төлеу күні',
  paymentDateNew: 'Төлеу күні',
  payUpTo: 'Дейін төлеңіз',
  сhangeMap: 'Картаны өзгерту',
  cancelSubscription: 'Жазылымнан бас тарту',
  subscription: 'Жазылым',
  rate: 'Тариф',
  underProtection: 'қорғауда',
  startRate: 'Тарифтің басталу күні',
  // rate: 'Тарифтер',
  yourRate: 'Сіздің тарифңыз',
  firstPaymentDate: 'Бірінші төлем күні',
  youReallyUnsubscribe: 'Жазылымнан бас тартқыңыз келетініне сенімдісіз бе?',
  yourMark: 'Сіздің рейтингіңіз бізге қызметтерімізді жақсартуға көп көмектеседі.',
  yourCardDebited: 'Сіздің картаңыздан 10 ₸ шегеріледі, содан кейін картаға қайтарылады',
  good: 'Жақсы',
  cancel: 'Болдырмау',
  info: 'Ақпарат',
  goTariff: 'Барыңыз',

  canceledSubscription_1: 'Жазылым сәтті тоқтатылды',
  canceledSubscription_2: 'Жазылымыңызды тіркелгіңізде жаңартуға болады',
  paymentCardSuccessfully_1: 'Төлем картасы сәтті',
  paymentCardSuccessfully_2: 'Енді төлемдер осы картадан есептен шығарылады',
  tariffChanged_1: 'Тариф сәтті өзгертілді',
  tariffChanged_2: 'Тариф күшіне енеді ',
  noResultForSelect: 'Кешіріңіз, қолайлы нұсқалар жоқ'
}