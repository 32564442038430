export default {
    state: {
        selectedCity: 1
    },
    mutations: {
        SET_CITY(state, payload) {
            state.selectedCity = payload;
        }
    },
    actions: {
        getAboutUsCity({commit}, city_id) {
            commit("SET_CITY", city_id)
        }
    },
    getters: {
        selectedAboutUsCity(state) {
            return state.selectedCity;
        }
    }
};
