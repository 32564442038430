import moment from "moment";
const POLIS_URL = 'https://797.polisonline.kz'

import {
  changePhoneNumber, changeSelectedProductId, changeSelectedProductName, deletePerson, deleteCar,
  getPeopleByIin, getCarByLicensePlate, getProducts, setProducts, isHaveBasketFriend,
  getCascoLite, getFixSum, getFixSumByProduct, setTempPolicy, sumVerification, sendOTP,
  verifyOTP, sendErrorsToRedmine, saveNotificationsToRedmine, checkClientsDoc
} from "../helpers/ogpoFunctions";

import {
  clientsChecked,
  selectedProductId,
  availableProducts,
  autoplusProduct,
  isLoyalClient,
  minRiskRating,
  carYearAndCarType,
  productsRegion,
  isStandard,
  selectedProductName, noBasketRegions
} from "../helpers/ogpoGetters";
import {uuidv4} from "../../plugins/Helpers";
import _ from "lodash";


export default {
    namespaced: true,
    state: {
        draftId: uuidv4(),
        phoneNumber: null,
        clients: [],
        cars: [],
        oil: [],
        beginDate: moment().add(1, "days").format("YYYY-MM-DD"),
        period: 12,
        email: null,
        clientIin:null,
        carNumber: null,
        autoplus: true,
        selectedProductId: 0,
        selectedProductName: "",
        friendBasket: false,
        discountBool: false,
        invoiceId: null,
        homebankToken: null,
        products: [],
        calculatedSum: {},
        baseSumm: 0,
        totalSum: 0,
        afterDicsountSumm: 0,
        otpKey: null,
        otpCode: false,
        allProducts: null,
        isClient: false,
        cascoSelected: false,
        cascoOptions: [],
        cascoModel: [],
        cascoReward: 0,
        addSumError: null,
    },
    mutations: {
        "SET_PHONE_NUMBER": (state, payload) => { state.phoneNumber = payload     },
        "SET_PERIOD": (state, payload) => { state.period = payload; },
        "SET_BEGIN_DATE": (state, payload) => { state.beginDate = payload; },
        "SET_EMAIL": (state, payload) => { state.email = payload; },
        "ADD_CLIENT": (state, payload) => { state.clients.push(payload) },
        "REMOVE_CLIENT": (state, index) => { state.clients.splice(index, 1); },
        "ADD_CAR": (state, payload) => { state.cars.push(payload) },
        "REMOVE_CAR": (state, index) => { state.cars.splice(index, 1) },
        "SET_SELECTED_PRODUCT_ID": (state, productId) => { state.selectedProductId = parseInt(productId) },
        "SET_SELECTED_PRODUCT_NAME": (state, name) => { state.selectedProductName = name },
        "SET_PRODUCTS": (state, payload) => { state.products = payload },
        "SET_FRIEND_BASKET": (state, payload) => { state.friendBasket = payload },
        "SET_BASESUMM": (state, payload) => { state.baseSumm = payload },
        "SET_CALCULATED_SUM": (state, payload) => { state.calculatedSum = JSON.parse(JSON.stringify(payload)) },
        "SET_CASCO_LITE_SELECTED": (state, payload) => { state.cascoSelected = payload },
        "SET_CASCO_LITE_OPTIONS": (state, payload) => { state.cascoOptions = payload },
        "SET_CASCO_LITE_REWARD": (state, payload) => { state.cascoReward = payload },
        "SET_ADDSUMERROR": (state, payload) => state.addSumError = payload,
        "SET_INVOICEID": (state, payload) => state.invoiceId = payload,
        "SET_OTPKEY": (state, payload) => state.otpKey = payload,
        "SET_OTPCODE": (state, payload) => state.otpCode = payload,
        "REPLACE_CLIENT": (state, payload) => {
          state.clients.splice(payload.index, 1, payload.client)
        },
        "RESET_OGPO_EXTENSION_FORM": (state) => {
            state.clients = [],
            state.cars = [],
            state.beginDate = moment().add(1, "days").format("YYYY-MM-DD")
        },
    },
    getters: {
      phoneNumber: state => state.phoneNumber,
      clients: state => state.clients,
      cars: state => state.cars,
      email: state => state.email,
      beginDate: state => {
        let beginDate = moment(state.beginDate);
        let today = moment().startOf("day");
        let tomorrow = moment().add(1, 'days');

        if (today.isSameOrAfter(beginDate, "days")) {
          beginDate = tomorrow;
        }

        return beginDate.format('YYYY-MM-DD');
      },
      endDate: (state, getters) => {
        const {period, beginDate} = getters;
        return moment(beginDate).add(period, "M").subtract(1, "days").format('YYYY-MM-DD');
      },
      calculatedSum: state => state.calculatedSum,
      period: state => state.period,
      casco: state => state.casco,
      products: state => state.products,
      allProducts: state => state.products,
      friendBasket: state => state.friendBasket,
      baseSumm: state => state.baseSumm,
      cascoSelected: state => state.cascoSelected,
      cascoOptions: state => state.cascoOptions,
      cascoReward: state => state.cascoReward,
      addSumError: state => state.addSumError,
      invoiceId: state => state.invoiceId,
      homebankToken: state => state.homebankToken,
      otpKey: state => state.otpKey,
      otpCode: state => state.otpCode,
      selectedProductId,
      selectedProductName,
      availableProducts,
      autoplusProduct,
      isLoyalClient,
      minRiskRating,
      carYearAndCarType,
      productsRegion,
      noBasketRegions,
      isStandard,
      clientsChecked,
      finalFormValue: (state, getters, root) => {
        return {
          draft_id: state.draftId,
          phone_number: root.auth.authUser.phone_number,
          insurance_type: 1000,
          clients: getters.clients.map((client) => ({individual_id_number: client.iin, full_name: client.fullName})),
          start_date: getters.startDate,
          period: getters.period,
          end_date: getters.endDate,
          cars: getters.cars.map(car => ({ govNumber: car.govNumber, territory: car.territory })),
          selectedProductName: getters.selectedProductName,
          minRiskRating: getters.minRiskRating,
          calculatedSum: getters.calculatedSum,
          otpKey: getters.otpKey,
          otpCode: getters.otpCode,
          source: 'mobile'
        }
      }
    },
    actions: {
      changePhoneNumber,
      changeSelectedProductId,
      changeSelectedProductName,
      deletePerson,
      deleteCar,
      getPeopleByIin,
      getCarByLicensePlate,
      getProducts,
      setProducts,
      isHaveBasketFriend,
      getCascoLite,
      getFixSum,
      getFixSumByProduct: async({commit, getters }) => {
          commit("SET_CALCULATED_SUM", null);

          const { cars, clients, selectedProductId, friendBasket, period, baseSumm, cascoReward } = getters;

          if(cars.length === 0 || clients.length === 0) { return; }

          if(Number(baseSumm) === 0) {
              commit("SET_ADDSUMERROR", "Не удалось получить базовый расчет");
              return;
          }

          let discountBool = true;

          clients.forEach(client => discountBool = client.discountBool);

          if (cars.length > 1) { discountBool = false }

          let ProductID = 0;

          if (selectedProductId) {
              ProductID = selectedProductId;
          } else {
              commit("SET_SELECTED_PRODUCT_ID", 0);
              commit("SET_SELECTED_PRODUCT_NAME", "");
          }

          const postData = {
              discountBool, friendBasket, ProductID,
              Sum: String(baseSumm),
              Kasko: String(cascoReward),
              Month: Number(period)
          }

          try{
              const { data } = await window.axios.post(`${POLIS_URL}/api/GetFixSumByProduct`, postData)

              commit("SET_ADDSUMERROR", "");
              commit("SET_CALCULATED_SUM", data);
              let updateSumEnd;
              //Окончание
              if(ProductID === 1 || ProductID === 2 || ProductID === 3 || ProductID === 4 || ProductID === 11){
                  updateSumEnd = true;
              } else{
                  updateSumEnd = false;
              }
          } catch (err) {
              let stage = {
                  name: 'Оформление полиса',
                  id: '5',
                  error: err
              };
              // await dispatch('sendErrorsToRedmine', stage)
          }
      },
      setTempPolicy: async({commit, getters, dispatch}) => {
          const requestObject = {
              guid: null,
              ClientID: [],
              CarID: [],
              BeginDate: "",
              PhoneNumber: "",
              PaymentRef: "",
              Iins: [],
              GovNumbers: [],
              TerritoryId: [],
              ProductID: null,
              FriendBasket: false,
              RequestId: String(getters.REQUEST_ID || 0),
              BaseSum: String(getters.baseSumm),
              Client: String(getters.COUNTERPARTY || ''),
              AdmitadUid: String(getters.ADMITAD_UID || '')
          }

          getters.clients.forEach((client, index) => {
              if (index === 0) {
                  requestObject["MainClientID"] = client["getPeopleId"];
                  requestObject["MainIin"] = client["iin"];
              } else {
                  requestObject["ClientID"].push(client["getPeopleId"]);
                  requestObject["Iins"].push(client["iin"]);
              }
          });

          getters.cars.forEach((car) => {
              requestObject["CarID"].push(car["carId"]);
              requestObject["GovNumbers"].push(car["govNumber"]);
              requestObject["TerritoryId"].push(car["territory"]);
          });

          requestObject["ProductID"] = Number(getters.selectedProductId)
          requestObject["FriendBasket"] = getters.friendBasket;
          requestObject["BeginDate"] = getters.beginDate;
          requestObject["EndDate"] = getters.endDate;
          requestObject["verifyType"] = 1;
          requestObject["MemberID"] = "641"
          requestObject.PhoneNumber = `7${getters.phoneNumber}`;

          if (getters.calculatedSum?.kasko) {
            requestObject.kasko = String(getters.calculatedSum?.kasko);
          }

          try {
              let { data } = await window.axios.post(`${POLIS_URL}/api/SetTempPolicy`, requestObject);

              commit("SET_INVOICEID", data);
          } catch (err) {
              console.log(err)
          }
      },
      sumVerification: async({ getters, dispatch }) => {
        const dataObj = {
          CarID: getters.cars.map((car) => car.carId),
          ClientID: getters.clients.map((client) => client.getPeopleId),
          BeginDate: getters.beginDate,
          EndDate: getters.endDate,
        };

        const {data} = await window.axios.post(`${POLIS_URL}/api/GetVerifyFixSum`, dataObj);

        if (data?.statusCode !== 200) {
          throw {message: data?.value || 'Произошла ошибка при сверке суммы. Повторите попытку позднее'}
        }
        const verifiedFixedSum = parseFloat(data.value);

        if (getters.calculatedSum["discountSum"] !== 0) {
          if (verifiedFixedSum != parseFloat(getters.calculatedSum["discountSum"])) {
            getters.client.forEach((client) => {
              client["checked"] = false;
            });
            getters.cars.forEach((car) => {
              car["checked"] = false;
            });

            throw {
              message: `После подтверждения документов мы произвели перерасчет и обнаружили, что указанные вами данные о стаже/инвалидности не верные. \nСтоимость страховки до перерасчета без учета скидок=
                ${getters.calculatedSum["discountSum"]}\nСтоимость страховки после перерасчета без учета скидок =${data.value}`
            }
          }
        } else {
          if (verifiedFixedSum != parseFloat(getters.calculatedSum["sum"])) {
            getters.clients.forEach((client) => {
              client["checked"] = false;
            });
            getters.cars.forEach((car) => {
              car["checked"] = false;
            });

            throw {
              message: `После подтверждения документов мы произвели перерасчет и обнаружили, что указанные вами данные о стаже/инвалидности не верные. \nСтоимость страховки до перерасчета без учета скидок=
                ${getters.calculatedSum["discountSum"]}\nСтоимость страховки после перерасчета без учета скидок = ${data.value}`
            }
          }
        }
      },
      sendOTP,
      verifyOTP,
      sendErrorsToRedmine,
      saveNotificationsToRedmine,
      checkClientsDoc,
      resetForm({commit}) {
          commit("RESET_OGPO_EXTENSION_FORM")
      },
      saveDraft: _.debounce(({ dispatch }) => {
        dispatch('saveWithoutDebounce');
      }, 1000),
      saveWithoutDebounce:  ({getters}) => {
        window.axios.post('/policy-draft', getters.finalFormValue)
      }

    },
};
